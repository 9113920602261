import axios from 'axios'
import { cookie } from '@utils'

const axiosPost = (url, data, options = {}) => {
  if (url.indexOf('http') === -1) {
    url = `${location.origin}/api/${url}`
  }

  let headers = {}

  if (options.headers) {
    headers = options.headers
  } else {
    options.headers = {
      'Content-Type': 'application/json'
    }
  }

  const csrfCookie = cookie.get('csrfCookie')
  if (csrfCookie) {
    options.headers['x-csrf-token'] = csrfCookie
  } else {
    return null
  }
  options.withCredentials = true

  let body = null

  if (options.customBody) {
    body = data
  } else {
    body = JSON.stringify(data)
  }

  let method = 'POST'

  if (options.method) {
    method = options.method
  }

  return axios.post(url, body, options)
}

export default axiosPost
