import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Dashboard from '@wrappers/Dashboard'
import { Title, Datatable, Icon, Tooltip } from '@lib'
import { Link, ListView } from '@shared'
import Swal from 'sweetalert2'

import clientCustomersGql from '@graphql/queries/client-customers'
import destroyClientCustomer from '@graphql/mutators/destroy-client-customer'
import { Can } from '@caslConfig/can.js'

const Content = ({ user }) => {
  const clientCustomersQuery = useQuery(clientCustomersGql, {
    fetchPolicy: 'network-only',
    variables: {}
  })

  const handleDelete = (v) => {
    Swal.fire({
      text: 'Are you sure you want to delete this client?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        document.querySelector('.modal-backdrop').style.display = 'block'
        document.querySelector('.modal-backdrop > .modal').style.display = 'none'

        destroyClientCustomer({ id: v }).then(() => {
          document.querySelector('.modal-backdrop').style.display = 'none'
          document.querySelector('.modal-backdrop > .modal').style.display = 'inherit'
          Swal.fire({
            text: 'Client Customer deleted',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          clientCustomersQuery.refetch()
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Client Customer',
        accessor: 'name'
      },
      {
        id: 'actionBtnsCol',
        accessor: 'id',
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='update' a='ClientCustomer'>
                <Link
                  className='action-button edit-button'
                  to={`/datalogic/client-customers/edit/${value}`}
                  icon='fas fa-pencil-alt'
                  id={`${value}-edit`}
                />
                <Tooltip
                  anchorId={`${value}-edit`}
                  content='Edit'
                />
              </Can>
              <Can I='delete' a='ClientCustomer'>
                <div onClick={() => handleDelete(value)} className='action-button delete-button' id={`${value}-delete`}>
                  <Icon className='fas fa-trash'/>
                </div>
                <Tooltip
                  anchorId={`${value}-delete`}
                  content='Delete'
                />
              </Can>
            </div>
          )
        }
      }
    ],
    []
  )

  if (clientCustomersQuery.loading || clientCustomersQuery.error) {
    return null
  }
  const { clientCustomers } = clientCustomersQuery.data

  return (
    <Fragment>
      <Title text='Client Customers' user={user} />

      <ListView>
        <Can I='create' a='ClientCustomer'>
          <Datatable
            columns={columns}
            data={clientCustomers}
            newRowBtn={{
              icon: 'fas fa-plus',
              text: 'New Client Customer',
              to: '/datalogic/client-customers/new'
            }}
          />
        </Can>
        <Can not I='create' a='ClientCustomer'>
          <Datatable
            columns={columns}
            data={clientCustomers}
          />
        </Can>
      </ListView>
    </Fragment>
  )
}

const ClientCustomers = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

export default ClientCustomers
