import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { LoadOverlay, Button } from '@lib'
import * as tableau from '@utils/tableau-embed-api.js'
import { DashboardExecutionFilters, SubscriptionForm, UserDashboardExecutionList, AlertForm, RlpUi, FinanceApprovalForm, PlanReview } from '@shared'
import { Input } from '@shared/Form'
import { cookie, getCurrentMonthName } from '@utils'

import client from '@graphql/client'
import authGql from '@graphql/queries/auth'
import noStickyFilterDashboardsGql from '@graphql/queries/no-sticky-filter-dashboards'
import trustedTicketGql from '@graphql/queries/trusted-ticket'
import tableauServerSettingGql from '@graphql/queries/tableau-server-setting'
import clientDashboardGql from '@graphql/queries/client-dashboard'
import clientRebateApproverGql from '@graphql/queries/client-rebate-approver'
import clientFinanceLogGql from '@graphql/queries/client-finance-log'

import sessionWatcher from '@graphql/mutators/session-watcher'
import updateUserClientHomepage from '@graphql/mutators/update-user-client-homepage'
const { TableauViz, TableauEventType, TableauDialogType, FilterUpdateType } = tableau

const TableauViz2 = ({
  setTitle,
  user,
  alertFilters,
  isBidlogic,
  setShowAlertsModal,
  setShowSubscriptionModal,
  showAlertsModal,
  showSubscriptionModal,
  onDashboardExecutionChange,
  clearAllFilters,
  children
}) => {
  let { workbook, view } = useParams()
  // setting dashboard to HomeScorecard until workbooks are done for bidlogic
  if (isBidlogic) {
    workbook = 'BL3_0'
    view = 'RateSummary'
  }
  const [loading, setLoading] = useState(true)
  const [loaderState, setLoaderState] = useState({
    overlay: true,
    overlayLoaderType: 'preloader'
  })
  const [isDashboardExecution, setIsDashboardExecution] = useState(null)
  let viewDashExec = false
  const [checkingExecution, setCheckingExecution] = useState(true)
  const [executionLoaderState, setExecutionLoaderState] = useState({
    overlay: false,
    overlayLoaderType: 'component'
  })
  const [rlpShowDash, setRlpShowDash] = useState(false) // SET TO FALSE TO ENABLE RLP UI
  const [tableauServer, setTableauServer] = useState()
  const [noStickyFiltersList, setNoStickyFiltersList] = useState(['Custom', 'BidLogic', 'RebateLogic', 'Covid'])
  const filterStorage = localStorage.getItem('filter-storage')
  const [_stickyFilters, _setStickyFilters] = useState(filterStorage)
  const stickyFiltersRef = useRef(_stickyFilters)
  const [rlpFilts, setRlpFilts] = useState()

  const [showFinanceApprovalForm, setShowFinanceApprovalForm] = useState(false)
  const [financeSummaryFormValues, setFinanceSummaryFormValues] = useState({})
  const [loadPlanReviewModal, setLoadPlanReviewModal] = useState(false)

  let stickyFilters
  const setStickyFilters = (data) => {
    stickyFiltersRef.current = data
    stickyFilters = stickyFiltersRef.current
    _setStickyFilters(data)
  }
  stickyFilters = stickyFiltersRef.current

  const tempStickyFilterStorage = localStorage.getItem('temp-filter-storage')

  let disableFilterWatch = false
  let blockNewStickyFilts = false
  let trustedTicket = null
  let tabServer = null
  let currentDashboardExecutionFilter = null
  let viz = null
  const ccagNames = ['Client Name', 'Carrier Name', 'Account Name', 'Group Name']
  const rlpWorkbooks = ['RebateLogicProposal', 'MedicalProposal', 'MedicalRebates', 'PharmacyRebates']

  if (window.location.search.includes('?filters')) {
    const filtQs = '?' + window.location.search.split('?').find(q => q.includes('filters'))
    const viewQs = (window.location.search.includes('?loadView') && '?' + window.location.search.split('?').find(q => q.includes('loadView')))
    localStorage.setItem('filter-storage', filtQs)
    setStickyFilters(filtQs)
    window.history.pushState({}, document.title, window.location.origin + window.location.pathname + (!viewQs ? '' : viewQs))
  }

  const handleSessionWatcher = () => {
    sessionWatcher().then(({ data, errors, extensions }) => {
      const session = data.sessionWatcher
      if (session) {
        cookie.set('sessionExpires', parseInt(session.expiresAt), 15)
        cookie.set('sessionWarning', session.sessionWarningTime, 15)
      }
    })
  }

  async function getTrustedTicket () {
    return await client.query({
      query: trustedTicketGql,
      fetchPolicy: 'no-cache'
    }).then(({ data }) => {
      trustedTicket = data.trustedTicket

      const urlPath = location.pathname.split('/')
      if (!isBidlogic) {
        loadTableauViz(trustedTicket, urlPath[2], urlPath[3])
      } else {
        loadTableauViz(trustedTicket, workbook, view)
      }

      return trustedTicket
    })
  }

  async function getTableauServerSetting () {
    await client.query({
      query: tableauServerSettingGql,
      fetchPolicy: 'no-cache'
    }).then(async ({ data }) => {
      try {
        setTableauServer(data.tableauServerSetting?.settingValue)
        tabServer = data.tableauServerSetting?.settingValue
      } catch (err) {
        console.log('Tableau Server URL not found.', err)
      }
    })
  }

  const getMarks = (newStickyFilters, actionFiltersToReplace) => {
    let worksheetCount = 0
    viz.workbook.activeSheet.worksheets.forEach(worksheet => {
      worksheet.getSelectedMarksAsync().then(mark => {
        if (mark.data.length > 0 && !blockNewStickyFilts) {
          for (let i = 0; i < mark.data.length; i++) {
            const markSheet = mark.data[i]
            if (markSheet.columns.length > 0) {
              for (const column of markSheet.columns) {
                const fieldName = column.fieldName
                const fieldVal = markSheet.data.map(data => data[column.index]?.value).join(',')
                if (actionFiltersToReplace.includes(fieldName) && column.dataType !== 'float' && !column.fieldName.includes('AGG') && fieldVal) {
                  actionFiltersToReplace = actionFiltersToReplace.filter(filter => filter !== fieldName)
                  newStickyFilters += '&a|' + encodeURIComponent(fieldName) + '=' + encodeURIComponent(fieldVal)
                }
              }
              worksheetCount = 0
              if (newStickyFilters !== stickyFilters) {
                localStorage.setItem('filter-storage', newStickyFilters)
                setStickyFilters(newStickyFilters)
              }
            } else {
              worksheetCount++
              if (worksheetCount === viz.workbook.activeSheet.worksheets.length) {
                localStorage.setItem('filter-storage', newStickyFilters)
                setStickyFilters(newStickyFilters)
              }
            }
          }
        }
      })
    })
  }

  let runningFilters = []
  const handleFilterChange = (event) => {
    if (disableFilterWatch) {
      // NO FILTER
      return
    }
    let fieldName = event.detail.fieldName
    if (fieldName.includes('Dynamic Date') || fieldName.toLowerCase().includes('nsf_')) {
      return
    }
    if (!runningFilters.includes(fieldName)) {
      runningFilters.push(fieldName)
      setTimeout(() => { runningFilters = [] }, 2000)

      let newStickyFilters = (stickyFilters !== null && stickyFilters ? stickyFilters : '?filters')
      if (!stickyFiltersRef.current) {
        newStickyFilters = '?filters'
      }

      if (fieldName.includes('Action (')) {
        const inParensMatch = /\(([^(]+)\)/
        const actionFiltersToReplace = fieldName.match(inParensMatch)[1].replace(')', '').split(',')
        for (const actionFilter of actionFiltersToReplace) {
          const stringMatch = new RegExp('[?&]a\\|' + encodeURIComponent(actionFilter) + '=.*?(?=&|$)', 'g')
          if (stickyFilters && stickyFilters.match(stringMatch)) {
            newStickyFilters = newStickyFilters.replace(stringMatch, '')
          }
        }
        getMarks(newStickyFilters, actionFiltersToReplace)
        return
      }

      if (stickyFilters?.includes('a|' + encodeURIComponent(fieldName))) {
        return
      }

      event.detail.getFilterAsync().then((alteredFilter) => {
        const trackObj = {
          userId: user.id,
          workbookName: viz.workbook.name,
          worksheetName: viz.workbook.activeSheet.name,
          filterName: alteredFilter.fieldName,
          isAllSelected: alteredFilter.isAllSelected,
          appliedValues: (alteredFilter.isAllSelected ? 'All' : alteredFilter.appliedValues.map((v) => v.value).join(',')),
          worksheetUrl: viz.workbook.activeSheet.url,
          dashboardUrl: window.location.href,
          fieldId: alteredFilter.fieldId,
          filterType: alteredFilter.filterType,
          isExcludeMode: alteredFilter.isExcludeMode
        }
        window.pendo.track('Filter Change', {
          ...trackObj
        })

        if (!blockNewStickyFilts) {
          newStickyFilters = (stickyFilters !== null && stickyFilters ? stickyFilters : '?filters')
          if (alteredFilter.filterType === 'categorical' && !fieldName.includes('Action (') && !fieldName.includes('Dynamic Date')) {
            fieldName = fieldName.replace('_', ' ')
            const stringMatch = new RegExp('[?&]' + encodeURIComponent(fieldName) + '=.*?(?=&|$)', 'g')
            if (stickyFilters && stickyFilters.match(stringMatch)) {
              newStickyFilters = newStickyFilters.replace(stringMatch, '')
            }
            if (alteredFilter.appliedValues?.length > 0 && !alteredFilter.isAllSelected) {
              newStickyFilters += '&' + encodeURIComponent(fieldName) + '=' + encodeURIComponent(alteredFilter.appliedValues.map((v) => v.value.replace(/,/g, '\\,')).join(','))
            }
            localStorage.setItem('filter-storage', newStickyFilters)
            setStickyFilters(newStickyFilters)
          }
        } else if (stickyFilters === 'null') {
          setStickyFilters('')
        }
      })
    }
  }

  const watchMarks = (event) => {
    event.detail.getMarksAsync().then((alteredMark) => {
      const alteredMarks = alteredMark.data[0]
      if (alteredMarks.columns.length > 0) {
        const trackedField = alteredMarks.columns.find(c => !c.fieldName.includes('SUM(') && !c.fieldName.includes('AGG(')) || alteredMarks.columns[0]
        const trackObj = {
          userId: user.id,
          workbookName: viz.workbook.name,
          worksheetName: viz.workbook.activeSheet.name,
          fieldName: trackedField.fieldName,
          fieldValue: alteredMarks.data.map(colVal => colVal[trackedField.index]?.value).join(','),
          worksheetUrl: viz.workbook.activeSheet.url,
          dashboardUrl: window.location.href,
          fieldId: trackedField.fieldId
        }
        window.pendo.track('Action Filter Change', {
          ...trackObj
        })
      }
    })
  }

  let runningParametersCount = 0
  let parametersRanCount = 0
  let newStickyFilters

  const handleParamChange = (event) => {
    runningParametersCount++
    event.detail.getParameterAsync().then((parameter) => {
      if (!parameter.name.toLowerCase().includes('nsf_')) {
        if (!blockNewStickyFilts) {
          window.pendo.track('Parameter Change', {
            userId: user.id,
            workbookName: viz.workbook.name,
            worsheetName: viz.workbook.activeSheet.name,
            parameterName: parameter.name,
            parameterValue: parameter.currentValue.value,
            parameterFormattedValue: parameter.currentValue.formattedValue,
            worksheetUrl: viz.workbook.activeSheet.url,
            dashboardUrl: window.location.href,
            parameterId: parameter.id,
            dataType: parameter.dataType
          })

          if (!parameter.name.includes('@Sheet') && !ccagNames.includes(parameter.name)) {
            if (parametersRanCount === 0 || !newStickyFilters) {
              newStickyFilters = (stickyFilters !== null && stickyFilters ? stickyFilters : '?filters')
              if (!stickyFiltersRef.current) {
                newStickyFilters = '?filters'
              }
            }
            const paramNameId = encodeURIComponent(parameter.name) + '|' + encodeURIComponent(parameter.id.split('.')[1].replace('[', '').replace(']', ''))
            const parameterStringMatch = new RegExp('[?&]p\\|' + paramNameId.replace('|', '\\|').replace('(', '\\(').replace(')', '\\)') + '=.*?(?=&|$)', 'g')
            if (newStickyFilters && newStickyFilters.match(parameterStringMatch)) {
              newStickyFilters = newStickyFilters.replace(parameterStringMatch, '')
            }
            newStickyFilters += '&p|' + paramNameId + '=' + encodeURIComponent(parameter.currentValue.value)
            parametersRanCount++
          } else {
            parametersRanCount++
          }

          if (runningParametersCount === parametersRanCount) {
            runningParametersCount = 0
            parametersRanCount = 0
            if (newStickyFilters) {
              const oldJustFilts = stickyFilters?.replace('?filters', '')?.split('&')?.filter(f => f && !f.includes('p|'))
              const newJustFilts = newStickyFilters?.replace('?filters', '')?.split('&')?.filter(f => f && !f.includes('p|'))
              const newStickyFiltersToAdd = oldJustFilts?.filter(f => !newJustFilts?.includes(f))
              if (newStickyFiltersToAdd?.length > 0) {
                newStickyFilters += '&' + newStickyFiltersToAdd.join('&')
              }

              localStorage.setItem('filter-storage', newStickyFilters)
              setStickyFilters(newStickyFilters)
              newStickyFilters = undefined
            }
          }
        } else { runningParametersCount = 0; parametersRanCount = 0 }
      }
    })
  }

  const checkIfApprover = async () => {
    await client.query({
      fetchPolicy: 'no-cache',
      query: clientRebateApproverGql
    }).then(async ({ data }) => {
      if (data.clientRebateApprover) {
        await fetchFinanceSummaryLog()
      }
    })
  }
  const fetchFinanceSummaryLog = async () => {
    await client.query({
      fetchPolicy: 'no-cache',
      query: clientFinanceLogGql,
      variables: {
        period: getCurrentMonthName()
      }
    }).then(({ data }) => {
      if (data.clientFinanceLog) {
        // set stateValues for finance summary approval form
        toggleFinanceApprovalForm(data.clientFinanceLog)
      }
    })
  }

  const toggleFinanceApprovalForm = (data) => {
    setFinanceSummaryFormValues(data)
    setShowFinanceApprovalForm(true)
  }

  const handleTabSwitch = async (event) => {
    handleSessionWatcher()
    if (event.detail.oldSheetName !== event.detail.newSheetName) {
      // EFA-325: Adjusting workbook size on tab switch
      const size = viz.workbook.activeSheet.size.maxSize
      document.getElementById('tableau-viz-tabs-contain').style.maxWidth = size.width + 'px'
      viz.width = size.width
      viz.height = size.height + 100

      const isFinanceSummaryDashboard = window.location.href.includes('FinanceSummary') // will change this to financial summary dashboard
      if (isFinanceSummaryDashboard && user.client.contentUrl.includes('_STAGE')) {
        await checkIfApprover()
      } else {
        setShowFinanceApprovalForm(false)
      }

      if (stickyFilters && stickyFilters.split('&').length > 0) {
        const filterStrings = stickyFilters?.split('&')
        disableFilterWatch = true
        const promises = []

        filterStrings.forEach(filterString => {
          if (filterString !== '?filters' && filterString.includes('a|')) {
            const filterField = decodeURIComponent(filterString.split('=')[0]).replace('a|', '')
            const filterVals = decodeURIComponent(filterString.split('=')[1]).split(',')

            promises.push(viz.workbook.activeSheet.applyFilterAsync(filterField, filterVals, FilterUpdateType.Replace))
          }
        })

        await Promise.all(promises).then(() => {
          setTimeout(() => {
            disableFilterWatch = false
          }, 1500)
        })
      }

      // handle if tabswitch happened on dashboard button
      const tabContainer = document.getElementById('tableau-viz-tabs')
      const tabs = tabContainer.getElementsByTagName('span')

      for (let i = 0; i < tabs.length; i++) {
        if (tabs[i].innerText === event.detail.newSheetName) {
          tabs[i].classList.add('active')
          setTitle(event.detail.newSheetName)
          window.history.pushState(null, null, event.detail.newSheetName.replaceAll(' ', ''))
        } else if (tabs[i].innerText === event.detail.oldSheetName) {
          tabs[i].classList.remove('active')
        }
      }
    }
  }

  const handleActiveTabChange = async (target) => {
    handleSessionWatcher()
    await setTitle(target.innerText)

    const activeTab = document.querySelectorAll('.tableau-viz-tab.active')
    activeTab.forEach(tab => {
      tab.classList.remove('active')
    })

    const activeDropdownTab = document.querySelectorAll('.dropdown-tabs-popup ul li.active')
    activeDropdownTab.forEach(tab => {
      tab.classList.remove('active')
    })

    const workbookTabs = document.querySelectorAll('.tableau-viz-tab')

    for (let i = 0; i < workbookTabs.length; i++) {
      if (workbookTabs[i].innerHTML === target.innerHTML) {
        workbookTabs[i].classList.add('active')
      }
    }

    const workbookDropdownTabs = document.querySelectorAll('.dropdown-tabs-popup ul li')

    for (let i = 0; i < workbookDropdownTabs.length; i++) {
      if (workbookDropdownTabs[i].innerHTML === target.innerHTML) {
        workbookDropdownTabs[i].classList.add('active')
      }
    }
  }

  const handleToolbarStateChanged = async (event) => {
    handleSessionWatcher()
    if (viz.workbook.name && !event.detail.canUndo) {
      setTimeout(() => {
        if (!viz.workbook.activeCustomView) {
          // console.log('remove toolbar listener ---')
          blockNewStickyFilts = false
          viz.addEventListener(TableauEventType.FilterChanged, handleFilterChange)
          viz.addEventListener(TableauEventType.ParameterChanged, handleParamChange)
          viz.removeEventListener(TableauEventType.ToolbarStateChanged, handleToolbarStateChanged)
        }
      }, 1000)
    }
  }

  const handleSubscriptionsNoStickyFilters = async () => {
    localStorage.setItem('temp-filter-storage', stickyFilters || '?filters')
    localStorage.removeItem('filter-storage')
    setStickyFilters('')
  }

  const handleCustomViewLoad = async (event) => {
    handleSessionWatcher()
    const pathMatch = /.*\/(views|dashboard)\//g
    const customViewUrl = new URL(event.detail.customView.url)
    const customViewUrlPath = customViewUrl.pathname.replace(pathMatch, '').split('/').slice(0, 2).join('/')
    const activeSheetUrl = new URL(event.detail.customView.workbook.activeSheet.url)
    const activeSheetUrlPath = activeSheetUrl.pathname.replace(pathMatch, '').split('/').slice(0, 2).join('/')
    const targetUrl = new URL(event.target.baseURI)
    const targetUrlPath = targetUrl.pathname.replace(pathMatch, '').split('/').slice(0, 2).join('/')
    const customViewNameMatch = /([^/]*)$/g

    blockNewStickyFilts = true
    viz.removeEventListener(TableauEventType.FilterChanged, handleFilterChange)
    viz.removeEventListener(TableauEventType.ParameterChanged, handleParamChange)
    viz.removeEventListener(TableauEventType.ToolbarStateChanged, handleToolbarStateChanged)
    setTimeout(() => {
      // console.log('add toolbar listener ~~~')
      viz.addEventListener(TableauEventType.ToolbarStateChanged, handleToolbarStateChanged)
      viz.removeEventListener(TableauEventType.FilterChanged, handleFilterChange)
      viz.removeEventListener(TableauEventType.ParameterChanged, handleParamChange)
      blockNewStickyFilts = true
    }, 2000)

    if (localStorage.getItem('filter-storage')?.includes('?filters&')) {
      await viz.revertAllAsync()
    }
    localStorage.removeItem('filter-storage')
    setStickyFilters('')

    if (activeSheetUrlPath === targetUrlPath && activeSheetUrlPath !== customViewUrlPath) {
      if (activeSheetUrl.pathname.match(customViewNameMatch)[0] !== customViewUrl.pathname.match(customViewNameMatch)[0]) {
        // console.log('SWITCH TAB TO CUSTOM VIEW')
        const activeTab = document.querySelectorAll('.tableau-viz-tab.active')
        const contentUrl = '/dashboard/' + customViewUrlPath
        const newTab = document.querySelector(`.tableau-viz-tab[contentUrl="${contentUrl}"]`)

        activeTab.forEach(tab => {
          tab.classList.remove('active')
        })
        newTab.classList.add('active')
        window.history.pushState(null, null, contentUrl)
        await setTitle(newTab.innerText)
        const decodedTabName = new DOMParser().parseFromString(newTab.innerHTML, 'text/html').body.innerText
        await viz.workbook.activateSheetAsync(decodedTabName)
      }
    }
  }

  const handleFirstInt = async () => {
    handleSessionWatcher()
    // USE FOR SEEING ALL PARAMETERS IN A WORKBOOK
    // viz.workbook.getParametersAsync().then(parameters => {
    //   console.log('Workbook Parameters: ')
    //   console.log(parameters)
    //   const watchNames = ['Months of Data', 'Rate Summary Type']
    //   parameters.forEach(p => {
    //     if (watchNames.includes(p.name)) {
    //       console.log(p)
    //     }
    //   })
    // })

    // apply alert filters
    // viz.width = viz.workbook.activeSheet.size.minSize.width

    const isFinanceSummaryDashboard = window.location.href.includes('FinanceSummary') // will change this to financial summary dashboard
    if (isFinanceSummaryDashboard && user.client.contentUrl.includes('_STAGE')) {
      await checkIfApprover()
    }
    if (alertFilters !== null && alertFilters.alert !== null) {
      const { reportLevel, reportLevelName, keyPerformanceIndicator, interval } = alertFilters.alert
      await viz.workbook.activeSheet.applyFilterAsync('Report Level', [reportLevel], 'replace')
      await viz.workbook.activeSheet.applyFilterAsync('Report Level Name', [...reportLevelName.split('|')], 'replace')
      await viz.workbook.activeSheet.applyFilterAsync('Interval', [interval], 'replace')
      await viz.workbook.activeSheet.applyFilterAsync('Measure Name', [keyPerformanceIndicator], 'replace')
    }

    // VALIDATES VIZ-PARAMETER HTML TAGS WITH WORKBOOK PARAMETERS, TO HANDLE PARAMETERS WITH SAME ID
    const paramTags = document.querySelectorAll('viz-parameter')
    if (paramTags?.length > 0) {
      viz.workbook.getParametersAsync().then(async parameters => {
        let newStickyFilters = (stickyFilters !== null && stickyFilters ? stickyFilters : '?filters')
        let needReset = false
        for (const paramTag of paramTags) {
          let newParamId = paramTag.getAttribute('name')
          if (paramTag.getAttribute('name').includes('|')) {
            newParamId = '[Parameters].[' + newParamId.split('|')[1]
          } else {
            newParamId = ''
          }
          let paramObj = parameters.find(p => p.id === newParamId)

          if (isBidlogic) {
            if (!paramTag.getAttribute('name').includes('[Parameters].[')) {
              paramObj = parameters.find(p => p.name === paramTag.getAttribute('name').replace('[Parameters].[', '').replace(']', ''))
              paramTag.setAttribute('name', paramObj.id)
              needReset = true
            }
          } else if (paramObj?.allowableValues?.type === 'list' && !newParamId) {
            const paramIdFromTag = encodeURIComponent(paramTag.getAttribute('name').split('.')[1]?.replace('[', '').replace(']', '')).replace('(', '\\(').replace(')', '\\)')
            const nameMatch = new RegExp('[^\\|]*?(?=\\|' + paramIdFromTag + '=.*?)')
            const paramTagNameFromStorage = decodeURIComponent(newStickyFilters.match(nameMatch) ? newStickyFilters.match(nameMatch)[0] : '')

            if (paramObj && (!paramObj?.allowableValues?.allowableValues?.find(av => av.value === paramTag.getAttribute('value')) || paramObj.name !== paramTagNameFromStorage)) {
              paramTag.remove()
              needReset = true

              const stringMatch = new RegExp('[?&]p\\|[^\\|]*\\|' + paramIdFromTag + '=.*?(?=&|$)', 'g')
              if (newStickyFilters && newStickyFilters.match(stringMatch)) {
                newStickyFilters = newStickyFilters.replace(stringMatch, '')
              }
            }
          } else if (newParamId) {
            paramTag.setAttribute('name', newParamId)
            needReset = true
          }
        }

        if (needReset) {
          localStorage.setItem('filter-storage', newStickyFilters)
          setStickyFilters(newStickyFilters)
          // eslint-disable-next-line no-self-assign
          const tt = await getTrustedTicket()
          viz.src = viz.src.replace(/trusted\/.*\/t/g, 'trusted/' + tt + '/t')
        }
      })
    }

    if (!isDashboardExecution) {
      setLoaderState(prevState => ({ ...prevState, overlay: false }))
    } else {
      setExecutionLoaderState(prevState => ({ ...prevState, overlay: false }))
      if (document.querySelector('#tableau-viz-tabs-contain')) {
        document.querySelector('#tableau-viz-tabs-contain').classList.remove('hidden')
      }
    }

    const activeSheet = viz.workbook.activeSheet.name
    setTitle(activeSheet)

    const oldTabs = document.getElementById('tableau-viz-tabs-contain')
    while (oldTabs.firstChild) {
      oldTabs.removeChild(oldTabs.firstChild)
    }

    const tabs = viz.workbook.publishedSheetsInfo
    let currentTab = ''

    const tabContainer = document.createElement('div')
    tabContainer.id = 'tableau-viz-tabs'
    document.getElementById('tableau-viz-tabs-contain').appendChild(tabContainer)

    if (tabs.length > 1) {
      await tabs.forEach(tab => {
        let dashPath = tab.url.split('views/', 2)[1]
        dashPath = dashPath.split('/')[0] + '/' + dashPath.split('/')[1]
        const contentUrl = '/dashboard/' + dashPath

        currentTab = document.createElement('span')
        currentTab.classList.add('tableau-viz-tab', 'button')
        currentTab.setAttribute('contentUrl', contentUrl)
        currentTab.innerText = tab.name

        if (tab.name === activeSheet) {
          currentTab.classList.add('active')
        }

        currentTab.onclick = async function (e) {
          if (!isBidlogic) {
            window.history.pushState(null, null, contentUrl)
          }
          await handleActiveTabChange(e.target)

          // EFA-249: Changed from e.target.innerText to innerHTML to account for any 'invisible' or special character on the sheet name
          const decodedTabName = new DOMParser().parseFromString(e.target.innerHTML, 'text/html').body.innerText
          await viz.workbook.activateSheetAsync(decodedTabName)
        }
        tabContainer.appendChild(currentTab)
      })

      const tabList = document.getElementById('tableau-viz-tabs').children
      let totalTabWidth = 0

      for (let ctr = 0; ctr < tabList.length; ctr++) {
        totalTabWidth += parseInt(tabList[ctr].offsetWidth, 10)
      }

      if (totalTabWidth > viz.workbook.activeSheet.size.maxSize.width) {
        let currentTabPosition = 0

        const handleTabDropdown = (e) => {
          e.preventDefault()
          e.stopPropagation()

          const dropdownTabsPopup = document.querySelector('.dropdown-tabs-popup')
          dropdownTabsPopup.classList.toggle('expand')
          if (dropdownTabsPopup.classList.contains('expand')) {
            const watchForCursor = (ee) => {
              handleSessionWatcher()
              if (!ee.target.closest('.dropdown-tabs-popup')) {
                dropdownTabsPopup.classList.remove('expand')
                document.removeEventListener('click', watchForCursor)
              }
            }
            document.addEventListener('click', watchForCursor)
          }
        }

        const handleTabNavigation = (direction) => {
          const tabs = document.querySelectorAll('#tableau-viz-tabs > .tableau-viz-tab')
          const tabContainer = document.querySelector('#tableau-viz-tabs')
          let xScroll = 0

          if (direction === 'prev') {
            currentTabPosition--
            rightButton.classList.remove('disabled')
          } else if (direction === 'next') {
            currentTabPosition++
            leftButton.classList.remove('disabled')
          } else {
            if (currentTabPosition === 0) {
              leftButton.classList.add('disabled')
            } else {
              leftButton.classList.remove('disabled')
            }

            for (let ctr = 0; ctr < tabs.length - 1; ctr++) {
              if (!tabs[ctr].classList.contains('active')) {
                currentTabPosition++
              } else {
                break
              }
            }
          }

          for (let ctr = 0; ctr < currentTabPosition; ctr++) {
            xScroll += tabs[ctr].clientWidth
          }

          tabContainer.scrollLeft = xScroll

          if (direction === 'prev') {
            if (currentTabPosition > 0) {
              leftButton.classList.remove('disabled')
            } else {
              leftButton.classList.add('disabled')
            }
          } else if (direction === 'next') {
            if ((tabContainer.scrollLeft + tabContainer.clientWidth) >= tabContainer.scrollWidth) {
              rightButton.classList.add('disabled')
            } else {
              rightButton.classList.remove('disabled')
            }
          }
        }

        const dropdownButton = document.createElement('span')
        dropdownButton.id = 'dropdown-btn'
        dropdownButton.classList.add('tableau-viz-tab-nav', 'button', 'fas', 'fa-sort-down')
        dropdownButton.onclick = (e) => handleTabDropdown(e)

        const leftButton = document.createElement('span')
        leftButton.id = 'prev-btn'
        leftButton.classList.add('tableau-viz-tab-nav', 'button', 'fas', 'fa-arrow-left')
        leftButton.onclick = () => handleTabNavigation('prev')

        const rightButton = document.createElement('span')
        rightButton.id = 'next-btn'
        rightButton.classList.add('tableau-viz-tab-nav', 'button', 'fas', 'fa-arrow-right')
        rightButton.onclick = () => handleTabNavigation('next')

        document.getElementById('tableau-viz-tabs-contain').prepend(leftButton)
        document.getElementById('tableau-viz-tabs-contain').prepend(dropdownButton)
        document.getElementById('tableau-viz-tabs-contain').appendChild(rightButton)

        const dropdownTabsContainer = document.createElement('div')
        dropdownTabsContainer.classList.add('dropdown-tabs-popup')
        document.getElementById('dropdown-btn').appendChild(dropdownTabsContainer)

        const dropdownTabsPopup = document.querySelector('.dropdown-tabs-popup')
        const ul = document.createElement('ul')
        dropdownTabsPopup.appendChild(ul)

        await tabs.forEach(tab => {
          let dashPath = tab.url.split('views/', 2)[1]
          dashPath = dashPath.split('/')[0] + '/' + dashPath.split('/')[1]
          const contentUrl = '/dashboard/' + dashPath

          const dropdownTabOption = document.createElement('li')
          dropdownTabOption.setAttribute('contentUrl', contentUrl)
          dropdownTabOption.innerText = tab.name

          if (tab.name === activeSheet) {
            dropdownTabOption.classList.add('active')
          }

          dropdownTabOption.onclick = async function (e) {
            window.history.pushState(null, null, contentUrl)
            await handleActiveTabChange(e.target)

            // EFA-249: Changed from e.target.innerText to innerHTML to account for any 'invisible' or special character on the sheet name
            const decodedTabName = new DOMParser().parseFromString(e.target.innerHTML, 'text/html').body.innerText
            await viz.workbook.activateSheetAsync(decodedTabName)
          }

          const dropdownTabList = document.querySelector('.dropdown-tabs-popup > ul')
          dropdownTabList.appendChild(dropdownTabOption)
        })

        const tabNavButton = document.getElementById('next-btn')
        document.querySelector('#tableau-viz-tabs').style.width -= tabNavButton.clientWidth

        handleTabNavigation(null)
      }
    }
    if (window.location.href.toLowerCase().includes('planreview')) {
      setLoadPlanReviewModal(true)
    }

    document.getElementById('tableau-viz-tabs-contain').style.maxWidth = viz.workbook.activeSheet.size.maxSize.width + 'px'
    document.getElementById('tableau-viz-actions').style.visibility = 'visible'
    window.history.pushState({}, document.title, window.location.origin + window.location.pathname)
  }

  const vizRef = useRef(null)

  const handleViewDashboardExecution = (userDashboardExecution) => {
    handleSessionWatcher()
    currentDashboardExecutionFilter = [userDashboardExecution.id]

    if (document.querySelector('#tableauViz')) {
      document.querySelector('#tableau-viz-tabs-contain').classList.add('hidden')
      document.querySelector('#tableauViz').remove()
    }

    if (!document.querySelector('#tableauViz')) {
      viewDashExec = true
      setExecutionLoaderState(prevState => ({ ...prevState, overlay: true, overlayLoaderType: 'componentLoader' }))
      getTrustedTicket()
    }
  }

  const checkDashboardExecution = async () => {
    if (!isBidlogic) {
      await client.query({
        fetchPolicy: 'no-cache',
        query: clientDashboardGql,
        variables: {
          dashboardName: workbook
        }
      }).then(({ data }) => {
        if (data?.clientDashboard?.dashboardName) {
          setIsDashboardExecution(true)
          setCheckingExecution(false)
        } else {
          setIsDashboardExecution(false)
          setCheckingExecution(false)
        }
      })
    } else {
      setIsDashboardExecution(false)
      setCheckingExecution(false)
    }
  }

  useEffect(() => {
    if (loading) {
      if (vizRef.current) {
        client.query({
          query: noStickyFilterDashboardsGql
        }).then(({ data }) => {
          if (data.noStickyFilterDashboards) {
            const noStickyFiltersArr = data.noStickyFilterDashboards.map(dashboard => dashboard.value)
            setNoStickyFiltersList(noStickyFiltersArr)
          }
        })
        setLoading(false)
      }
    }
  }, [loading, workbook, view])

  useEffect(() => {
    setRlpShowDash(false) // COMMENT TO DISABLE RLP UI
    viewDashExec = null
    setCheckingExecution(true)
    setIsDashboardExecution(null)
    checkDashboardExecution()

    if (!loading) {
      trustedTicket = null

      // This check with tableauVizWrap was added to handle cases where the user hits Back button on browser
      const tableauVizWrap = document.getElementById('tableauVizWrap')
      const tableauViz = document.getElementById('tableauViz')
      setTitle('')

      if (tableauVizWrap && tableauViz) {
        tableauVizWrap?.removeChild(tableauViz)

        document.getElementById('tableau-viz-actions').style.visibility = 'hidden'

        const oldTabs = document.getElementById('tableau-viz-tabs-contain')
        while (oldTabs.firstChild) {
          oldTabs.removeChild(oldTabs.firstChild)
        }

        setLoading(true)
        setLoaderState(prevState => ({ ...prevState, overlay: true }))
      }
    }
  }, [workbook, view])

  useEffect(() => {
    if (isDashboardExecution) {
      document.getElementById('tableau-viz-actions').style.visibility = 'visible'
    }
  }, [isDashboardExecution])

  // Whenever the isDashboardExecution state changes, the parent component will be notified
  useEffect(() => {
    if (onDashboardExecutionChange) {
      onDashboardExecutionChange(isDashboardExecution)
    }
  }, [isDashboardExecution, onDashboardExecutionChange])

  useEffect(() => {
    if (clearAllFilters) {
      setStickyFilters('')
    }
  }, [clearAllFilters])

  if (window.location.search.includes('?loadView')) {
    const viewToLoad = window.location.search.match(/loadView(.*?)(?=&|$)/g)[0].replace('loadView=', '')
    viz = document.getElementById('tableauViz')
    if (viz) {
      if (viewToLoad === 'og') {
        if (viz.workbook.activeCustomView) {
          viz.workbook.showCustomViewAsync()
          window.history.pushState({}, document.title, window.location.origin + window.location.pathname)
          setTimeout(() => { console.clear() }, 500)
        } else {
          window.history.pushState({}, document.title, window.location.origin + window.location.pathname)
        }
      } else {
        const customViewPath = decodeURIComponent(viewToLoad).split('/')[0] + '/' + decodeURIComponent(viewToLoad).split('/')[1]
        window.history.pushState({}, document.title, window.location.origin + '/dashboard/' + customViewPath)
      }
    }
  }

  const loadTableauViz = (ticket, workbookName, viewName) => {
    handleSessionWatcher()
    if (vizRef.current && !loading && (!isDashboardExecution || viewDashExec) && !checkingExecution && ticket && (!rlpWorkbooks.includes(workbook) || (rlpWorkbooks.includes(workbook) && rlpShowDash))) {
      if (document.querySelector('#tableauViz')) {
        viz = document.getElementById('tableauViz')
      } else {
        viz = new TableauViz()
        viz.id = 'tableauViz'
        const viewToLoad = window.location.search.match(/loadView(.*?)(?=&|$)/g)?.[0].replace('loadView=', '')
        if (window.location.search.includes('?loadView') && viewToLoad !== 'og') {
          viz.src = `https://${tableauServer || tabServer}/trusted/` + ticket + (user.client.contentUrl && '/t/' + user.client.contentUrl) + '/views/' + decodeURIComponent(viewToLoad)
        } else {
          viz.src = `https://${tableauServer || tabServer}/trusted/` + ticket + (user.client.contentUrl && '/t/' + user.client.contentUrl) + '/views/' + workbookName + '/' + viewName

          if (isDashboardExecution) {
            viz.addFilter('Execution Id', currentDashboardExecutionFilter)
          }
        }
        viz.toolbar = 'top'
        viz.hideTabs = true
        viz.hideEditButton = true
        viz.addEventListener(TableauEventType.FirstInteractive, handleFirstInt)

        viz.addEventListener(TableauEventType.FilterChanged, handleFilterChange)
        viz.addEventListener(TableauEventType.ParameterChanged, handleParamChange)
        viz.addEventListener(TableauEventType.TabSwitched, handleTabSwitch)
        viz.addEventListener(TableauEventType.CustomViewLoaded, handleCustomViewLoad)

        viz.addEventListener(TableauEventType.FilterChanged, handleSessionWatcher)
        viz.addEventListener(TableauEventType.MarkSelectionChanged, handleSessionWatcher)
        viz.addEventListener(TableauEventType.MarkSelectionChanged, watchMarks)
        viz.addEventListener(TableauEventType.ParameterChanged, handleSessionWatcher)
        viz.addEventListener(TableauEventType.TabSwitched, handleSessionWatcher)

        if (!noStickyFiltersList?.find(n => workbook.toLowerCase().includes(n.toLowerCase()))) {
          if (tempStickyFilterStorage) {
            localStorage.setItem('filter-storage', tempStickyFilterStorage)
            setStickyFilters(tempStickyFilterStorage)
            localStorage.removeItem('temp-filter-storage')
          }

          const filterStrings = stickyFilters?.split('&')
          filterStrings?.forEach(filterString => {
            if (filterString !== '?filters') {
              let filterField = decodeURIComponent(filterString.split('=')[0])
              const filterVal = decodeURIComponent(filterString.split('=')[1])
              if (filterField.includes('p|')) {
                filterField = filterField.replace('p|', '')
                if (!isBidlogic) {
                  filterField = '[Parameters].[' + filterField.replace(/p\|.*\|/, '') + ']'
                }
                // filterField = filterField.match(/[^&p|].*(?=\|)/)[0] // MATCHES NAME INSTEAD OF ID
                const parameterElement = document.createElement('viz-parameter')
                parameterElement.setAttribute('name', filterField)
                parameterElement.setAttribute('value', filterVal)
                viz.appendChild(parameterElement)
              } else {
                filterField = filterField.replace('a|', '')
                viz.addFilter(filterField, filterVal)

                if (workbook.includes('HomeScorecard') || view.includes('HomeScorecard')) {
                  if (ccagNames.includes(filterField)) {
                    viz.addFilter(filterField.split(' ').join('_'), filterVal)
                  }
                }
              }
            }
          })
        } else {
          handleSubscriptionsNoStickyFilters()
        }

        if (rlpWorkbooks.includes(workbook) && rlpFilts) {
          for (const key in rlpFilts) {
            viz.addFilter(key, rlpFilts[key])
          }
          setRlpFilts()
        }

        document.getElementById('tableauVizWrap').appendChild(viz)
      }
    }
  }

  if (trustedTicket && !isDashboardExecution) {
    loadTableauViz()
  } else {
    handleSessionWatcher()
    if (!document.querySelector('#tableauViz')) {
      if (!tableauServer || tabServer) {
        getTableauServerSetting()
      } else if (!isDashboardExecution && !checkingExecution) {
        getTrustedTicket()
      }
    }
  }

  const applyRlpFilters = async (filters) => {
    if (!document.getElementById('tableauViz')) {
      setRlpFilts(filters)
    } else {
      viz = document.getElementById('tableauViz')
      for (const key in filters) {
        await viz.workbook.activeSheet.applyFilterAsync(key, [filters[key]], 'replace')
      }
    }
  }

  const generateVizUrl = (workbookName) => {
    let vizUrl

    if (workbookName && localStorage.tabNavs) {
      const tabNavs = JSON.parse(localStorage.tabNavs)[0]
      let workbook

      workbook = tabNavs.workbooks?.find(w => w.name.toLowerCase() === workbookName.toLowerCase())

      if (workbook) {
        vizUrl = `/dashboard/${workbook.contentUrl}/${workbook.defaultView.viewUrlName}`
      } else {
        for (const tn of tabNavs.children) {
          workbook = tn.workbooks.find(w => w.name.toLowerCase() === workbookName.toLowerCase())
          if (workbook) {
            vizUrl = `/dashboard/${workbook.contentUrl}/${workbook.defaultView.viewUrlName}`
          }
        }
      }
    }

    return vizUrl
  }

  const isHomeScorecard = () => {
    const isExecutiveDashboard = generateVizUrl('Executive Dashboard')

    if (isExecutiveDashboard) {
      if (localStorage.tabNavs) {
        const tabNavs = JSON.parse(localStorage.tabNavs)[0]
        let homeScorecard = tabNavs.workbooks?.find(w => w.contentUrl?.toLowerCase() === location.pathname.split('/')[2]?.toLowerCase())

        if (homeScorecard) {
          return homeScorecard.defaultView.name === 'Home Scorecard'
        } else {
          const execDashboardProject = tabNavs.children?.find(c => c.name?.toLowerCase() === 'executive dashboard')

          if (execDashboardProject) {
            homeScorecard = execDashboardProject.workbooks?.find(w => w.contentUrl?.toLowerCase() === location.pathname.split('/')[2]?.toLowerCase())

            if (homeScorecard) {
              return homeScorecard.defaultView.name === 'Home Scorecard'
            }
          }
        }
      }
    }

    return false
  }

  const updateUserHomepage = (e) => {
    viz = document.getElementById('tableauViz')

    updateUserClientHomepage({
      userId: user.id,
      isHomepageChanged: e,
      homepage: viz.workbook.activeSheet.url.split('views/')[1].split('/')[0]
    }).then(async ({ data }) => {
      if (data.updateUserClientHomepage) {
        const toggleElement = document.querySelector('.toggler-inner')

        if (e) {
          const sideNavLinks = document.querySelectorAll('.menu > ul > li > a')
          toggleElement.classList.remove('scorecard-toggler-inner')
          toggleElement.classList.add('scorecard-toggler-inner--active')

          sideNavLinks.forEach(link => {
            if (link.innerText === 'Home') {
              const viz = document.querySelector('#tableauViz')

              link.classList.add('active')
              link.href = generateVizUrl(viz.workbook.name)
            }
          })
        } else {
          const activeLink = document.querySelector('.menu > ul > li > a.active')
          toggleElement.classList.remove('scorecard-toggler-inner--active')
          toggleElement.classList.add('scorecard-toggler-inner')

          if (activeLink && activeLink.innerText === 'Home') {
            activeLink.classList.remove('active')
            activeLink.href = generateVizUrl('Executive Dashboard')
          }
        }

        await client.query({
          query: authGql,
          fetchPolicy: 'network-only'
        })
      }
    })
  }

  const isExecutiveDashboard = window.location.pathname.includes('ExecutiveDashboard')

  return (
    <>
    { showFinanceApprovalForm && <FinanceApprovalForm data={financeSummaryFormValues} user={user}/> }
    <div className='tableau-viz-contain'>
      <div id='tableau-viz-actions'>
        {isHomeScorecard() &&
          <div id='tableau-viz-actions--left'>
            <Input
              type='toggle'
              value={user.userClients.find(uc => uc.clientId === user.clientId).homepage}
              isScorecard={true}
              isHomepageActive={user.homepage !== null && user.homepage !== ''}
              onChange={(e) => updateUserHomepage(e)}
            />

            <p>Set as home</p>
          </div>
        }
      </div>
      {isDashboardExecution && !checkingExecution && (
        <>
          <DashboardExecutionFilters user={user} dashboardName={workbook}/>
          <UserDashboardExecutionList user={user} dashboardName={workbook} onClick={handleViewDashboardExecution} />
        </>
      )}

      {/* COMMENT TO DISABLE RLP UI */}
      {rlpWorkbooks.includes(workbook) && !checkingExecution && <RlpUi setState={setRlpShowDash} setTitleState={(v) => setTitle(v)} applyFilters={applyRlpFilters} />}

      <div id='tableau-viz-tabs-contain' className={isExecutiveDashboard ? 'executive-dashboard' : ''} />

      {children}
      {/* Plan Review component */}
      <PlanReview tableauDialogType={TableauDialogType} user={user} loadPlanReviewModal={loadPlanReviewModal}/>

      <div id='tableauVizWrap' className={isExecutiveDashboard ? '' : 'non-executive-dashboard'} ref={vizRef}>
        {isDashboardExecution && !checkingExecution && executionLoaderState.overlay && <LoadOverlay loaderType={executionLoaderState.overlayLoaderType} />}
      </div>
      {!isDashboardExecution && !rlpWorkbooks.includes(workbook) && loaderState.overlay && <LoadOverlay loaderType={loaderState.overlayLoaderType} />}
    </div>
    {showSubscriptionModal && <SubscriptionForm showModal={showSubscriptionModal} closeModal={() => setShowSubscriptionModal(false)} />}
    {showAlertsModal && <AlertForm showModal={showAlertsModal} closeModal={() => setShowAlertsModal(false)} user={user}/>}
    </>
  )
}
TableauViz2.propTypes = {
  setTitle: PropTypes.func,
  user: PropTypes.object,
  alertFilters: PropTypes.object,
  isBidlogic: PropTypes.bool,
  showAlertsModal: PropTypes.bool,
  showSubscriptionModal: PropTypes.bool,
  setShowAlertsModal: PropTypes.func,
  setShowSubscriptionModal: PropTypes.func,
  onDashboardExecutionChange: PropTypes.func,
  clearAllFilters: PropTypes.bool,
  children: PropTypes.node
}

export default TableauViz2
