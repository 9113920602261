import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title, Link } from '@lib'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { TemplateFieldMappingList, TemplateFieldValueList } from '@shared'
import 'react-tabs/style/react-tabs.css'

const Content = ({ user }) => {
  const tabs = {
    '#template-field-mappings': 0,
    '#template-field-values': 1
  }
  const identifyActiveTab = (tab) => {
    return tabs[tab] | 0
  }
  const { hash } = useLocation()
  const [tabIndex, setTabIndex] = useState(hash ? identifyActiveTab(hash) : 0)

  return (
    <Fragment>
      <Title text='Template Settings' user={user}/>

      <Tabs className='tab-view' selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className='tab-navs'>
          <Tab>
            <Link
              to='#template-field-mappings'
              onClick={() => { setTabIndex(identifyActiveTab('#template-field-mappings')) }}
            >
              Template Field Mappings
            </Link>
          </Tab>
          <Tab>
            <Link
              to='#template-field-values'
              onClick={() => { setTabIndex(identifyActiveTab('#template-field-values')) }}
            >
              Template Field Values
            </Link>
          </Tab>
        </TabList>

        <TabPanel>
          <TemplateFieldMappingList
            itemClass='background-white'
            links={{
              edit: '/datalogic/template-settings/edit-template-field-mapping'
            }}
          />
        </TabPanel>
        <TabPanel>
          <TemplateFieldValueList
            itemClass='background-white'
            links={{
              edit: '/datalogic/template-settings/edit-template-field-value'
            }}
          />
        </TabPanel>
      </Tabs>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const TemplateSettings = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default TemplateSettings
