import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import QuestionnaireList from '@shared/QuestionnaireList'

const Content = ({ user }) => {
  return (
    <Fragment>
      <Title text='Questionnaire Admin' user={user} />

      <QuestionnaireList
        itemClass='background-white'
        links={{
          edit: '/settings/questionnaire-admin/edit'
        }}
      />
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const QuestionnaireAdmin = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default QuestionnaireAdmin
