import client from '../client.js'
import destroyPlanReviewDownloadGql from '../mutations/destroy-plan-review-download.gql'

const destroyPlanReviewDownload = (input) => {
  return client.mutate({
    mutation: destroyPlanReviewDownloadGql,
    variables: input
  })
}

export default destroyPlanReviewDownload
