import client from '../client.js'
import createQuestionnaireGql from '../mutations/create-questionnaire.gql'

const createQuestionnaire = (input) => {
  return client.mutate({
    mutation: createQuestionnaireGql,
    variables: input
  })
}

export default createQuestionnaire
