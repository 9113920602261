import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { Datatable, Button, Icon, Tooltip } from '@lib'
import { Link } from '@shared'
import { ListView } from '@shared/ListView'

import { Buttons } from '@shared/Action'
import Modal from '@lib/Modal'
import { Can } from '@caslConfig/can.js'

import templateFieldAllowedValuesGql from '@graphql/queries/template-field-allowed-value'
import destroyTemplateFieldAllowedValue from '@graphql/mutators/destroy-template-field-allowed-value'

const universalClientName = 'All'

const TemplateFieldValueList = ({ links, fields, itemClass, onDelete, filters }) => {
  const [showFieldValue, setShowFieldValue] = useState(false)
  const [fieldValue, setFieldValue] = useState({
    client: '',
    template: '',
    templateField: '',
    equivalentValue: '',
    allowedValue: ''
  })

  const [formOptions, setFormOptions] = useState({
    templateFieldAllowedValues: []
  })

  // Query TemplateFieldValueAllowed

  const templateFieldAllowedValuesQuery = useQuery(templateFieldAllowedValuesGql, {
    fetchPolicy: 'network-only',
    onCompleted: ({ templateFieldAllowedValues }) => {
      const options = templateFieldAllowedValues.map(({ id, allowedValue, client, templateField, templateFieldValue }) => (
        {
          id,
          template: templateField.template.name,
          client: client ? client.name : universalClientName,
          templateField: templateField.fieldName,
          equivalentValue: templateFieldValue.value,
          allowedValue
        }
      ))
      setFormOptions((prevState) => ({
        ...prevState,
        templateFieldAllowedValues: options
      })
      )
    }
  })

  const handleDelete = (fieldValueID) => {
    Swal.fire({
      text: 'Are you sure you want to delete this allowed value?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        // Function destroyTemplateFieldValue
        destroyTemplateFieldAllowedValue(fieldValueID).then(() => {
          onDelete(fieldValueID)
          Swal.fire({
            text: 'Field value deleted!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          templateFieldAllowedValuesQuery.refetch()
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'client'
      },
      {
        Header: 'Template',
        accessor: 'template'
      },
      {
        Header: 'Template Field',
        accessor: 'templateField'
      },
      {
        Header: 'Equivalent Value',
        accessor: 'equivalentValue'
      },
      {
        Header: 'Allowed Value',
        accessor: 'allowedValue'
      },
      {
        id: 'actionBtnsCol',
        accessor: (n) => n,
        disableSortBy: true,
        Header: '',
        Cell: function createActionButtons ({ value }) {
          if (value.client !== universalClientName) {
            return (
              <div className='action-buttons'>
                <Can I='update' a='TemplateSetting'>
                  <Link
                    className='action-button edit-button'
                    to={`${links.edit}/${value.id}`}
                    icon='fas fa-pencil-alt'
                    id={`${value.id}-edit`}
                  />
                  <Tooltip
                    anchorId={`${value.id}-edit`}
                    content='Edit'
                  />
                </Can>
                <Can I='delete' a='TemplateSetting'>
                  <div
                    onClick={() => handleDelete(value.id)}
                    className='action-button delete-button'
                    id={`${value.id}-delete`}
                  >
                    <Icon
                      className='fas fa-trash'
                    />
                  </div>
                  <Tooltip
                    anchorId={`${value.id}-delete`}
                    content='Delete'
                  />
                </Can>
              </div>
            )
          } else {
            return null
          }
        }
      }
    ],
    []
  )

  return (
    <ListView>
      <Can I='create' a='TemplateSetting'>
        <Datatable
          columns={columns}
          data={formOptions.templateFieldAllowedValues} // TemplateFieldValue Data
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New Template Field Value',
            to: '/datalogic/template-settings/new-template-field-value'
          }}
        />
      </Can>
      <Can not I='create' a='TemplateSetting'>
        <Datatable
          columns={columns}
          data={formOptions.templateFieldAllowedValues}
        />
      </Can>

      <Modal
        show={showFieldValue}
        onClose={() => {
          setShowFieldValue(false)
        }}
      >
        <div className='header'>{fieldValue.client}</div>
        <div className='content'>
          <div className='field-value-template'>{fieldValue.template}</div>
          <div className='field-value-template-field'>{fieldValue.templateField}</div>
          <div className='field-value-equivalent-value'>{fieldValue.equivalentValue}</div>
          <div className='field-value-allowed-value'>{fieldValue.allowedValue}</div>

          <Buttons className='field-value'>
            <Button
              className='small white'
              text='OK'
              onClick={() => {
                setShowFieldValue(false)
              }}
            />
          </Buttons>
        </div>
      </Modal>
    </ListView>
  )
}

TemplateFieldValueList.propTypes = {
  links: PropTypes.object,
  fields: PropTypes.object,
  itemClass: PropTypes.string,
  onDelete: PropTypes.func,
  filters: PropTypes.object,
  value: PropTypes.string
}

TemplateFieldValueList.defaultProps = {
  links: {
    view: '',
    edit: ''
  },
  fields: {
    // Insert Fields
  },
  onDelete: () => {},
  filters: {},
  value: null
}

export default TemplateFieldValueList
