import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Loader } from '@shared'
import authGql from '@graphql/queries/auth'
import axios from 'axios'
import { cookie } from '@utils'

const getCSRFToken = async () => {
  const res = await axios.get(`${location.origin}/api/csrf-token`, { withCredentials: true })
  cookie.set('csrfCookie', res.data.csrfToken, 1)
}

const PageRoute = ({ authenticated, component: Component, allowedRoles, auth, loading, redirectRoles, redirectLocation, ...rest }) => {
  const systemNavigate = useNavigate()

  useEffect(() => {
    if (user && allowedRoles && !allowedRoles.includes(user.systemRole.name)) {
      return (
        systemNavigate(-1, { replace: true })
      )
    }
  }, [allowedRoles])

  const [loadingCSRF, setLoadingCSRF] = useState(true)

  useEffect(() => {
    if (!cookie.get('csrfCookie')) {
      getCSRFToken()
        .then(() => {
          setLoadingCSRF(false)
        })
    } else {
      if (!authenticated && loadingCSRF) {
        getCSRFToken()
          .then(() => {
            setLoadingCSRF(false)
          })
      } else {
        setLoadingCSRF(false)
      }
    }
  }, [cookie.get('csrfCookie')])

  const authQuery = useQuery(authGql, {
    skip: loadingCSRF
  })

  if (!authQuery?.loading && authQuery?.error?.networkError?.statusCode === 403) {
    cookie.set('csrfCookie', 0, -1)
    setLoadingCSRF(true)
    return null
  }

  if (!loadingCSRF && !cookie.get('csrfCookie')) {
    setLoadingCSRF(true)
    return <Loader />
  }

  const isDataLogicPageSiteSwitching = localStorage.getItem('siteSwitching') === 'true' && window.location.href.includes('datalogic')

  if (authQuery.loading || loadingCSRF || isDataLogicPageSiteSwitching) {
    return (
      <Loader />
    )
  }

  if (loading) {
    return null
  }

  const user = authQuery?.data?.auth

  if (authenticated && !user) {
    window.location.href = '/login'
  }

  if (allowedRoles && !allowedRoles.includes(user?.systemRole.name)) {
    return systemNavigate(-1, { replace: true })
  }

  if (redirectRoles.includes(user?.systemRole.name)) {
    return systemNavigate(redirectLocation, { replace: true })
  }

  /* WD-461 - SHORT-TERM SOLUTION: ADDED TO SUPPORT MULTIPLE CLIENTS ACCESSING DEMO SITES */
  if (user?.client?.name?.includes('Demo') && !(user?.systemRole?.name === 'Superuser' || user?.systemRole?.name === 'Xevant Admin')) {
    if (Component.name === 'Settings' || Component.name === 'Users') {
      return (
        systemNavigate(-1, { replace: true })
      )
    }
  }
  /* END WD-461 - SHORT-TERM SOLUTION: ADDED TO SUPPORT MULTIPLE CLIENTS ACCESSING DEMO SITES */

  return (
    <Routes>
      <Route
        element={<Component />}
        {...rest}
      />
    </Routes>
  )
}

PageRoute.propTypes = {
  authenticated: PropTypes.bool,
  allowedRoles: PropTypes.array,
  redirectRoles: PropTypes.array,
  redirectLocation: PropTypes.string,
  component: PropTypes.any,
  auth: PropTypes.object,
  loading: PropTypes.bool
}

PageRoute.defaultProps = {
  authenticated: false,
  loading: false,
  redirectRoles: [],
  redirectLocation: '/settings'
}

export default PageRoute
