import { InMemoryCache } from '@apollo/client'

const defaultTypePolicy = {
  merge (existing, incoming) {
    return incoming
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        auth: defaultTypePolicy,
        clients: defaultTypePolicy,
        clientUsers: defaultTypePolicy,
        clientCustomers: defaultTypePolicy,
        masterReferences: defaultTypePolicy
      }
    },
    Client: {
      fields: {
        clientUsers: defaultTypePolicy,
        clientModules: defaultTypePolicy
      }
    },
    User: {
      fields: {
        userClients: {
          merge (existing = [], incoming = []) {
            // Create a map of existing userClients
            const existingClientsMap = existing.reduce((map, client) => {
              map[client.clientId] = client
              return map
            }, {})

            // Iterate over the incoming userClients and merge them with the existing ones
            return incoming.map(client => {
              const existingClient = existingClientsMap[client.clientId]
              if (existingClient) {
                // If the incoming client is already in the map, merge it with the existing one
                return {
                  ...existingClient,
                  ...client
                }
              } else {
                // If the incoming client is not in the map, add it to the result
                return client
              }
            })
          }
        }
      }
    },
    Questionnaire: {
      fields: {
        questionnaireUsers: defaultTypePolicy
      }
    }
  }
})

export default cache
