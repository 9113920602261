import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { Button, LoadOverlay } from '@lib'
import { Form, Group, Input, Submit, FormError } from '@shared/Form'
import { array, object } from '@utils'

import client from '@graphql/client'
import questionnaireClientsGql from '@graphql/queries/questionnaire-clients'
import userClientsGql from '@graphql/queries/user-clients'
import createQuestionnaire from '@graphql/mutators/create-questionnaire'

import questionnaireGql from '@graphql/queries/questionnaire'
import updateQuestionnaire from '@graphql/mutators/update-questionnaire'

const QuestionnaireForm = ({ id, onSave, user }) => {
  const [input, setInput] = useState({
    clientId: null,
    questionnaireCount: null,
    users: [],
    apiToken: null
  })
  const [loading, setLoading] = useState(true)
  const [inputErrors, setInputErrors] = useState({})
  const [users, setUsers] = useState([])
  const [loaderState, setLoaderState] = useState({
    overlay: false,
    progress: 0,
    submit: false
  })

  const questionnaireClientsQuery = useQuery(questionnaireClientsGql, {
    variables: {
      isEditMode: !!id
    }
  })

  useEffect(() => {
    if (id && loading) {
      const variables = { id }

      questionnaireClientsQuery.refetch().then(() => {
        client.query({
          query: questionnaireGql,
          variables,
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          if (data.questionnaire) {
            const { questionnaire } = data
            client.query({
              query: userClientsGql,
              variables: {
                clientId: questionnaire.clientId,
                isQuestionnaire: true
              }
            }).then(({ data }) => {
              setUsers(data.userClients.map(userClient => ({ id: userClient?.user?.id, email: userClient?.user?.email })))

              setInput({
                id: questionnaire.id,
                clientId: questionnaire.clientId,
                questionnaireCount: questionnaire.questionnaireCount,
                users: questionnaire.questionnaireUsers.map(user => user.userId)
              })

              setLoading(false)
            })
          }
        })
      })
    } else {
      setLoaderState(prevState => ({ ...prevState }))
      setLoading(false)
    }
  }, [loading])

  useEffect(() => {
    if (input.clientId) {
      client.query({
        query: userClientsGql,
        variables: {
          clientId: input.clientId,
          isQuestionnaire: true
        }
      }).then(({ data }) => {
        setUsers(data.userClients.map(userClient => ({ id: userClient?.user?.id, email: userClient?.user?.email })))
      })
    }
  }, [!id && input.clientId])

  if (questionnaireClientsQuery.loading || loading) {
    return null
  }

  const { questionnaireClients } = questionnaireClientsQuery.data

  const handleInputChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newInput = { ...input }

    if (name === 'users') {
      newInput[name] = Array.isArray(value) ? value : []
    } else if (name === 'questionnaireCount' || name === 'clientId') {
      newInput[name] = parseInt(value, 10) || null
    } else {
      newInput[name] = value
    }

    setInput(newInput)
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}
    if (!input.clientId) {
      feErrors.clientId = [{ message: 'Client is required' }]
    }

    if (!input.questionnaireCount) {
      feErrors.questionnaireCount = [{ message: '# of Events is required' }]
    } else if (input.questionnaireCount < 1) {
      feErrors.questionnaireCount = [{ message: '# of Events must be greater than 0' }]
    }

    if (!input.users.length > 0) {
      feErrors.users = [{ message: 'At least one user must be selected' }]
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      if (id) {
        updateQuestionnaire(input).then(({ data, extensions }) => {
          if (data.updateQuestionnaire) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      } else {
        createQuestionnaire(input).then(({ data, extensions }) => {
          if (data.createQuestionnaire) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            setLoaderState(prevState => ({ ...prevState, submit: false }))
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  return (
    <Form className='questionnaire-form' onSubmit={handleSave}>
      <FormError errors={inputErrors} />

      <Group>
        <Input
          label='Client'
          type='select'
          placeholder='Select client'
          options={questionnaireClients.map(option => ({ value: option.id, text: option.name }))}
          value={input.clientId?.toString()}
          onChange={(value) => handleInputChange('clientId', value)}
          errors={inputErrors.clientId}
          disabled={!!id}
        />
      </Group>

      {id && user?.systemRole?.name === 'Superuser' && (
        <Group>
          <Input
            label='API Token'
            type='text'
            value={input.apiToken}
            onChange={(value) => handleInputChange('apiToken', value)}
            errors={inputErrors.apiToken}
            autoComplete='off'
          />
        </Group>
      )}

      <Group>
        <Input
          label='# of Events'
          type='number'
          value={input.questionnaireCount}
          onChange={(value) => handleInputChange('questionnaireCount', value)}
          errors={inputErrors.questionnaireCount}
          autoComplete='off'
        />
      </Group>

      <Group>
        <Input
          label='User List'
          type='multiselect'
          placeholder='Select users'
          options={users?.map(u => ({ value: u.id, text: u.email }))}
          value={input.users}
          onChange={(v) => handleInputChange('users', v)}
          errors={inputErrors.users}
          disabled={!input.clientId}
        />
      </Group>

      <Submit className='right'>
        <Button
          type='submit'
          className='primary'
          text={`${id ? 'Update' : 'Submit'} Questionnaire Setup`}
          loading={loaderState.submit}
        />
      </Submit>
      {loaderState.overlay && <LoadOverlay loaderType={loaderState.overlayLoaderType} progress={loaderState.progress} />}
    </Form>
  )
}
QuestionnaireForm.propTypes = {
  id: PropTypes.string,
  onSave: PropTypes.func,
  user: PropTypes.object
}
QuestionnaireForm.defaultProps = {
  onSave: () => {} // This is intentional
}

export default QuestionnaireForm
