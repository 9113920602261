import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Dashboard from '@wrappers/Dashboard'
import PropTypes from 'prop-types'
import { Title } from '@lib'
import Swal from 'sweetalert2'
import QuestionnaireForm from '@shared/QuestionnaireForm'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const handleSave = () => {
    Swal.fire({
      text: 'Questionnaire setup request created! Wait for confirmation email from Responsive.',
      icon: 'success',
      showConfirmButton: false,
      timer: 5000
    })

    navigate('/settings/questionnaire-admin')
  }

  return (
    <Fragment>

      <Title text='New Questionnaire Setup' user={user}/>

      <QuestionnaireForm
        onSave={handleSave}
        user={user}
      />
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const NewQuestionnaireSetup = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default NewQuestionnaireSetup
