import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import PropType from 'prop-types'
import { useQuery } from '@apollo/client'
import { Title } from '@lib'
import { Loader } from '@shared'
import Dashboard from '@wrappers/Dashboard'

import resourcesGql from '@graphql/queries/resources'

const Content = ({ user }) => {
  const { id } = useParams()

  const resourceQuery = useQuery(resourcesGql, {
    variables: {
      id
    }
  })

  if (resourceQuery.loading) {
    return <Loader/>
  } else if (resourceQuery.error) {
    return null
  }
  const { resources } = resourceQuery.data
  const resCourse = resources[0]

  return (
    <Fragment>

      <Title text={`${resCourse.type} - ${resCourse.name}`} user={user}/>

      <embed className='embed-course' type='text/html' src={location.origin + '/api' + (resCourse.url.startsWith('/') ? '' : '/') + resCourse.url} />

    </Fragment>
  )
}

Content.propTypes = {
  user: PropType.object
}

const EmbedCourse = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default EmbedCourse
