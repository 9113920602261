import React from 'react'
import PropTypes from 'prop-types'

import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate
} from 'react-router-dom'

import { PageRoute } from '@lib'

// global
import SessionPopUp from '@shared/SessionPopUp'

// common pages
import { NotFound } from '@screens/General/Pages'
import LoginScreen from '@screens/General/Login'
import AuthenticateScreen from '@screens/General/Authenticate'
import MfaSelect from '@screens/General/Authenticate/mfaSelect'
import EmailVerify from '@screens/General/Authenticate/emailVerify'
import ScanMfaScreen from '@screens/General/Authenticate/scan'
import MfaVerifyScreen from '@screens/General/Authenticate/verify'
import ForceUpdatePasswordScreen from '@screens/General/ForceUpdatePassword'
import ForgotPasswordScreen from '@screens/General/ForgotPassword'
import ForgotPasswordChangeScreen from '@screens/General/ForgotPassword/change'
import CreatePasswordScreen from '@screens/General/CreatePassword'
import ChangePasswordScreen from '@screens/General/ChangePassword'
import TosScreen from '@screens/General/Tos'
import Unauthorized from '@screens/General/Unauthorized'

import SettingsScreen from '@screens/Settings'
import UsersScreen from '@screens/Settings/Users'
import EditUserScreen from '@screens/Settings/Users/edit'
import NewUserScreen from '@screens/Settings/Users/new'
import SystemConfigScreen from '@screens/Settings/SystemConfig'
import AuditLogsScreen from '@screens/Settings/AuditLogs'
import ClientsListScreen from '@screens/Settings/Clients'
import NewClientScreen from '@screens/Settings/Clients/new'
import UserGroupsListScreen from '@screens/Settings/Clients/user-groups'
import NewUserGroupScreen from '@screens/Settings/Clients/new-user-group'

// admin - master references
import MasterReferencesScreen from '@screens/Settings/MasterReferences'
import NewMasterReferenceScreen from '@screens/Settings/MasterReferences/new'
import EditMasterReferenceScreen from '@screens/Settings/MasterReferences/edit'
import NoticesAndReleaseNotesScreen from '@screens/Settings/NoticesAndReleaseNotes'
import NewNoticeScreen from '@screens/Settings/NoticesAndReleaseNotes/new-notice'
import NewReleaseNoteScreen from '@screens/Settings/NoticesAndReleaseNotes/new-release-note'
import EditNoticeScreen from '@screens/Settings/NoticesAndReleaseNotes/edit-notice'
import EditReleaseNoteScreen from '@screens/Settings/NoticesAndReleaseNotes/edit-release-note'
import TrainingsListScreen from '@screens/Settings/TrainingsAndUseCases'
import EditTrainingScreen from '@screens/Settings/TrainingsAndUseCases/edit'
import UploadCourseScreen from '@screens/Settings/TrainingsAndUseCases/upload'

import AlertsScreen from '@screens/General/ManageAlerts'
import PlanReviewDownloadsScreen from '@screens/General/ManagePlanReviewDownloads'
import ResourcesLandingScreen from '@screens/Resources'
import ReleaseNotesScreen from '@screens/Resources/ReleaseNotes'
import ResourceTosScreen from '@screens/Resources/Tos'
import TrainingsAndUseCasesScreen from '@screens/Resources/TrainingsAndUseCases'
import EmbedCourseScreen from '@screens/Resources/TrainingsAndUseCases/embedCourse'

import TableauDashboardScreen from '@screens/General/TableauDashboard'

import ManageSubscriptionsScreen from '@screens/General/ManageSubscriptions'

// Datalogic Routes
import DataLogicHomeScreen from '@screens/DataLogic/Dashboard'
import DataLogicFileListScreen from '@screens/DataLogic/FileList'
import DataLogicNewFileUploadScreen from '@screens/DataLogic/FileUpload'
import DataLogicColumnResolver from '@screens/DataLogic/FileUpload/ColumnResolver'
import DataLogicFileErrorSummaryScreen from '@screens/DataLogic/FileUpload/error-summary'
import DataLogicTemplateSettingsScreen from '@screens/DataLogic/TemplateSettings'
import NewTemplateFieldMapping from '@screens/DataLogic/TemplateSettings/new-template-field-mapping'
import NewTemplateFieldValue from '@screens/DataLogic/TemplateSettings/new-template-field-value'
import EditTemplateFieldMapping from '@screens/DataLogic/TemplateSettings/edit-template-field-mapping'
import EditTemplateFieldValue from '@screens/DataLogic/TemplateSettings/edit-template-field-value'
import ClientCustomersListScreen from '@screens/DataLogic/ClientCustomers'
import NewClientCustomerScreen from '@screens/DataLogic/ClientCustomers/new'

// Questionnaire
import QuestionnaireAdminScreen from '@screens/Settings/QuestionnaireAdmin'
import NewQuestionnaireSetup from '@screens/Settings/QuestionnaireAdmin/new'
import EditQuestionnaireSetup from '@screens/Settings/QuestionnaireAdmin/edit'

const Routes = ({ children }) => {
  return (
    <Router>
      {children}

      <Switch>
        {/* start: public pages */}
        <Route
          path='login/*'
          element={
            <PageRoute
              index
              component={LoginScreen}
            />
          }
        />

        <Route
          path='authenticate/*'
          element={
            <PageRoute
              index
              component={AuthenticateScreen}
            />
          }
        />

        <Route
          path='authenticate/mfaselect/*'
          element={
            <PageRoute
              index
              component={MfaSelect}
            />
          }
        />

        <Route
          path='authenticate/mfacode/*'
          element={
            <PageRoute
              index
              component={EmailVerify}
            />
          }
        />

        <Route
          path='authenticate/scan/*'
          element={
            <PageRoute
              index
              component={ScanMfaScreen}
            />
          }
        />

        <Route
          path='authenticate/verify/*'
          element={
            <PageRoute
              index
              component={MfaVerifyScreen}
            />
          }
        />

        <Route
          path='settings/*'
          element={
            <PageRoute
              index
              component={SettingsScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/users/*'
          element={
            <PageRoute
              index
              component={UsersScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'Client Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/user/edit/:id/*'
          element={
            <PageRoute
              index
              component={EditUserScreen}
              allowedRoles={['Superuser', 'Client Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/user/new/*'
          element={
            <PageRoute
              index
              component={NewUserScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/master-references/*'
          element={
            <PageRoute
              index
              component={MasterReferencesScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/master-reference/new/*'
          element={
            <PageRoute
              index
              component={NewMasterReferenceScreen}
              allowedRoles={['Superuser']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/master-reference/edit/:id/*'
          element={
            <PageRoute
              index
              component={EditMasterReferenceScreen}
              allowedRoles={['Superuser']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/notices-and-release-notes/*'
          element={
            <PageRoute
              index
              component={NoticesAndReleaseNotesScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/notices-and-release-notes/new-notice/*'
          element={
            <PageRoute
              index
              component={NewNoticeScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/notices-and-release-notes/new-release-note/*'
          element={
            <PageRoute
              index
              component={NewReleaseNoteScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/notices-and-release-notes/edit-notice/:id/*'
          element={
            <PageRoute
              index
              component={EditNoticeScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/notices-and-release-notes/edit-release-note/:id/*'
          element={
            <PageRoute
              index
              component={EditReleaseNoteScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/system-config/*'
          element={
            <PageRoute
              index
              component={SystemConfigScreen}
              allowedRoles={['Superuser']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/audit-logs/*'
          element={
            <PageRoute
              index
              component={AuditLogsScreen}
              allowedRoles={['Superuser']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/clients/*'
          element={
            <PageRoute
              index
              component={ClientsListScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/clients/new/*'
          element={
            <PageRoute
              index
              component={NewClientScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/clients/edit/:id/*'
          element={
            <PageRoute
              index
              component={NewClientScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/clients/:id/user-groups/*'
          element={
            <PageRoute
              index
              component={UserGroupsListScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/clients/:id/user-groups/new/*'
          element={
            <PageRoute
              index
              component={NewUserGroupScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/clients/:id/user-groups/edit/:userGroupId/*'
          element={
            <PageRoute
              index
              component={NewUserGroupScreen}
              allowedRoles={['Superuser', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/trainings-and-use-cases/*'
          element={
            <PageRoute
              index
              component={TrainingsListScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/trainings-and-use-cases/edit/:id/*'
          element={
            <PageRoute
              index
              component={EditTrainingScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='help-center/trainings-and-use-cases/*'
          element={
            <PageRoute
              index
              component={TrainingsAndUseCasesScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='help-center/*'
          element={
            <PageRoute
              index
              component={ResourcesLandingScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='help-center/manage-alerts'
          element={
            <PageRoute
              index
              component={AlertsScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='help-center/release-notes/*'
          element={
            <PageRoute
              index
              component={ReleaseNotesScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='help-center/terms-of-service/*'
          element={
            <PageRoute
              index
              component={ResourceTosScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='help-center/trainings-and-use-cases/view/:id/*'
          element={
            <PageRoute
              index
              component={EmbedCourseScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='help-center/manage-plan-review-downloads/*'
          element={
            <PageRoute
              index
              component={PlanReviewDownloadsScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/trainings-and-use-cases/new/*'
          element={
            <PageRoute
              index
              component={UploadCourseScreen}
              allowedRoles={['Superuser', 'Xevant Admin', 'System Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='update-password/*'
          element={
            <PageRoute
              index
              component={ForceUpdatePasswordScreen}
              exact
              authenticated={true}
            />
          }
        />

        <Route
          path='forgot-password/*'
          element={
            <PageRoute
              index
              component={ForgotPasswordScreen}
              exact
            />
          }
        />

        <Route
          path='forgot-password/change/:token/*'
          element={
            <PageRoute
              index
              component={ForgotPasswordChangeScreen}
              exact
            />
          }
        />

        <Route
          path='create-password/:token/*'
          element={
            <PageRoute
              index
              component={CreatePasswordScreen}
              exact
            />
          }
        />

        <Route
          path='change-password/*'
          element={
            <PageRoute
              index
              component={ChangePasswordScreen}
              exact
            />
          }
        />

        <Route
          path='tos/*'
          element={
            <PageRoute
              index
              component={TosScreen}
              exact
            />
          }
        />
        <Route
          path='dashboard/:workbook/:view/*'
          element={
            <PageRoute
              index
              component={TableauDashboardScreen}
              authenticated={true}
              redirectRoles={['System Admin']}
            />
          }
        />

        <Route
          path='help-center/manage-subscriptions/*'
          element={
            <PageRoute
              index
              component={ManageSubscriptionsScreen}
              authenticated={true}
            />
          }
        />
        {/* DataLogic */}
        <Route
          path='datalogic/*'
          element={
            <PageRoute
              index
              component={DataLogicHomeScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/file-uploads/:directory/*'
          element={
            <PageRoute
              index
              component={DataLogicFileListScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/file-uploads/:directory/new-upload/*'
          element={
            <PageRoute
              index
              component={DataLogicNewFileUploadScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/template-settings/*'
          element={
            <PageRoute
              index
              component={DataLogicTemplateSettingsScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/template-settings/new-template-field-mapping/*'
          element={
            <PageRoute
              index
              component={NewTemplateFieldMapping}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/template-settings/new-template-field-value/*'
          element={
            <PageRoute
              index
              component={NewTemplateFieldValue}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/template-settings/edit-template-field-mapping/:id/*'
          element={
            <PageRoute
              index
              component={EditTemplateFieldMapping}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/template-settings/edit-template-field-value/:id/*'
          element={
            <PageRoute
              index
              component={EditTemplateFieldValue}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/file-uploads/:directory/file-resolver/:id/*'
          element={
            <PageRoute
              index
              component={DataLogicColumnResolver}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/file-uploads/:directory/error-summary/:id/*'
          element={
            <PageRoute
              index
              component={DataLogicFileErrorSummaryScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/client-customers/*'
          element={
            <PageRoute
              index
              component={ClientCustomersListScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/client-customers/new/*'
          element={
            <PageRoute
              index
              component={NewClientCustomerScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='datalogic/client-customers/edit/:id/*'
          element={
            <PageRoute
              index
              component={NewClientCustomerScreen}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/questionnaire-admin/*'
          element={
            <PageRoute
              index
              component={QuestionnaireAdminScreen}
              allowedRoles={['Superuser', 'System Admin', 'Xevant Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/questionnaire-admin/new/*'
          element={
            <PageRoute
              index
              component={NewQuestionnaireSetup}
              allowedRoles={['Superuser', 'System Admin', 'Xevant Admin']}
              authenticated={true}
            />
          }
        />

        <Route
          path='settings/questionnaire-admin/edit/:id/*'
          element={
            <PageRoute
              index
              component={EditQuestionnaireSetup}
              authenticated={true}
            />
          }
        />

        <Route
          path='invalid-link/*'
          element={
            <PageRoute
              index
              component={Unauthorized}
              authenticated={true}
            />
          }
        />

        {/* start: fallback page */}
        {/* <MyPageRoute component={NotFound} /> */}
        <Route
          path='/*'
          element={
            <Navigate
              to='/login'
              replace
            />
          }
        />
      </Switch>
      <SessionPopUp />
    </Router>
  )
}

Routes.propTypes = {
  children: PropTypes.object
}

export default Routes
