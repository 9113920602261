
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import { PlanReviewDownloadList } from '@shared'

const Content = ({ user }) => {
    //Logic
    return (
      <Fragment>
        <Title text='Manage Plan Review Downloads' user={user} />
        <PlanReviewDownloadList user={user} />
      </Fragment>
    )
}

const ManagePlanReviewDownloads = () => {
    return (
      <Dashboard>
        <Content/>
      </Dashboard>
    )
}
export default ManagePlanReviewDownloads

Content.propTypes = {
    user: PropTypes.object
}