import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import config from '@config'

export default function defineAbility (user) {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

  const systemRole = user.systemRole

  const viewDl = user.client.clientModules?.find(cm => cm.module.name === 'DataLogic') && user.userClients?.find(uc => uc.clientId === user.clientId).isDatalogicUser
  const viewBL = user.client.clientModules?.find(cm => cm.module.name === 'BidLogic')
  const bucketUrls = ['alcatraz.dev.xevant.io', 'x4.stage.xevant.io', 'app.xevant.com']
  if (config.APP_ENV === 'local') {
    bucketUrls.push('localhost')
  }

  switch (systemRole.name) {
    case 'Superuser':
      can('manage', 'all')

      if (!user.client.clientModules?.find(cm => cm.module.name === 'DataLogic') || !user.userClients?.find(uc => uc.clientId === user.clientId).isDatalogicUser) {
        cannot('read', 'DataLogicSettings')
        cannot('read', 'TemplateSetting')
        cannot('read', 'ClientCustomer')

        cannot('create', 'TemplateSetting')
        cannot('update', 'TemplateSetting')
        cannot('delete', 'TemplateSetting')

        cannot('create', 'ClientCustomer')
        cannot('update', 'ClientCustomer')
        cannot('delete', 'ClientCustomer')
      }

      if (!user.client.clientModules?.find(cm => cm.module.name === 'BidLogic')) {
        cannot('create', 'FileRunRequest')
        cannot('delete', 'FileRunRequest')
      }

      if (!bucketUrls.find(b => window.location.href.includes(b))) {
        cannot('create', 'Client')
        cannot('update', 'Client')

        cannot('create', 'Resource')
        cannot('update', 'Resource')

        cannot('create', 'UserGroup')
        cannot('update', 'UserGroup')
      }

      break
    case 'System Admin':
      can('read', 'Setting')
      can('read', 'Account')
      can('read', 'Client')
      can('read', 'Configuration')
      can('read', 'SyncPlanReview')

      can('read', 'User')
      can('create', 'User')
      can('update', 'User', { systemRole: { $nin: ['Superuser'] } })
      can('delete', 'User', { systemRole: { $nin: ['Superuser'] } })
      can('lock', 'User')
      can('resend', 'User')
      can('reset', 'User')
      can('resetMfa', 'User')

      if (bucketUrls.find(b => window.location.href.includes(b))) {
        can('create', 'Client')
        can('update', 'Client')

        can('create', 'Resource')
        can('update', 'Resource')
      }
      can('delete', 'Client')

      can('read', 'Resource')
      can('delete', 'Resource')

      can('create', 'Notice')
      can('read', 'Notice')
      can('update', 'Notice')
      can('delete', 'Notice')

      can('read', 'MasterReference')

      can('create', 'ReleaseNote')
      can('read', 'ReleaseNote')
      can('update', 'ReleaseNote')
      can('delete', 'ReleaseNote')

      can('read', 'TermsOfService')

      can('read', 'Alert')

      can('read', 'Subscription')
      can('update', 'Subscription')
      can('pause', 'Subscription')
      can('delete', 'Subscription')
      can('read', 'SubscriptionMember')
      can('pause', 'SubscriptionMember')
      can('delete', 'SubscriptionMember')

      can('read', 'FileUpload')

      can('create', 'UserGroup')
      can('read', 'UserGroup')
      can('update', 'UserGroup')
      can('delete', 'UserGroup')

      can('read', 'QuestionnaireSetup')
      can('create', 'QuestionnaireSetup')
      can('update', 'QuestionnaireSetup')
      can('delete', 'QuestionnaireSetup')

      can('read', 'PlanReviewDownload')

      break
    case 'Xevant Admin':
      can('read', 'Setting')
      can('read', 'Account')
      can('read', 'Configuration')
      can('read', 'SyncPlanReview')
      if (viewDl) {
        can('read', 'DataLogicSettings')
        can('read', 'TemplateSetting')
        can('read', 'ClientCustomer')

        can('create', 'TemplateSetting')
        can('update', 'TemplateSetting')
        can('delete', 'TemplateSetting')

        can('create', 'ClientCustomer')
        can('update', 'ClientCustomer')
        can('delete', 'ClientCustomer')

        can('create', 'FileUpload')
        can('update', 'FileUpload')
        can('delete', 'FileUpload')
        can('read', 'FileUpload')
      }

      if (viewBL) {
        can('create', 'FileRunRequest')
        can('delete', 'FileRunRequest')
      }

      can('read', 'User')

      can('lock', 'User', { systemRole: { $nin: ['Superuser'] } })
      can('resend', 'User', { systemRole: { $nin: ['Superuser'] } })
      can('reset', 'User', { systemRole: { $nin: ['Superuser'] } })
      can('resetMfa', 'User', { systemRole: { $nin: ['Superuser'] } })

      can('create', 'Notice')
      can('read', 'Notice')
      can('update', 'Notice')
      can('delete', 'Notice')

      can('create', 'ReleaseNote')
      can('read', 'ReleaseNote')
      can('update', 'ReleaseNote')
      can('delete', 'ReleaseNote')

      if (bucketUrls.find(b => window.location.href.includes(b))) {
        can('create', 'Resource')
        can('update', 'Resource')
      }
      can('read', 'Resource')
      can('delete', 'Resource')

      can('read', 'TermsOfService')

      can('read', 'Alert')

      can('create', 'Subscription')
      can('read', 'Subscription')
      can('update', 'Subscription')
      can('pause', 'Subscription')
      can('delete', 'Subscription')
      can('read', 'SubscriptionMember')
      can('pause', 'SubscriptionMember')
      can('delete', 'SubscriptionMember')

      can('read', 'Dashboard')
      can('create', 'Bookmark')
      can('read', 'Bookmark')
      can('delete', 'Bookmark')

      can('read', 'QuestionnaireSetup')
      can('create', 'QuestionnaireSetup')
      can('update', 'QuestionnaireSetup')
      can('delete', 'QuestionnaireSetup')

      can('read', 'PlanReviewDownload')

      break
    case 'Xevant Standard':
      can('read', 'ReleaseNote')
      can('read', 'Resource')
      can('read', 'TermsOfService')
      if (viewDl) {
        can('read', 'Setting')
        can('read', 'DataLogicSettings')
        can('read', 'TemplateSetting')
        can('read', 'ClientCustomer')

        can('create', 'TemplateSetting')
        can('update', 'TemplateSetting')
        can('delete', 'TemplateSetting')

        can('create', 'ClientCustomer')
        can('update', 'ClientCustomer')
        can('delete', 'ClientCustomer')

        can('create', 'FileUpload')
        can('update', 'FileUpload')
        can('delete', 'FileUpload')
        can('read', 'FileUpload')
      }

      if (viewBL) {
        can('create', 'FileRunRequest')
        can('delete', 'FileRunRequest')
      }

      can('read', 'Alert')

      can('create', 'Subscription')
      can('read', 'Subscription')
      can('pause', 'Subscription')
      can('delete', 'Subscription')

      can('read', 'Dashboard')
      can('create', 'Bookmark')
      can('read', 'Bookmark')
      can('delete', 'Bookmark')

      can('read', 'PlanReviewDownload')

      break
    case 'Client Admin':
      can('read', 'Setting')
      can('read', 'Account')
      if (viewDl) {
        can('read', 'DataLogicSettings')
        can('read', 'TemplateSetting')
        can('read', 'ClientCustomer')

        can('create', 'TemplateSetting')
        can('update', 'TemplateSetting')
        can('delete', 'TemplateSetting')

        can('create', 'ClientCustomer')
        can('update', 'ClientCustomer')
        can('delete', 'ClientCustomer')

        can('create', 'FileUpload')
        can('update', 'FileUpload')
        can('delete', 'FileUpload')
        can('read', 'FileUpload')
      }

      if (viewBL) {
        can('create', 'FileRunRequest')
        can('delete', 'FileRunRequest')
      }

      can('read', 'User')

      can('lock', 'User')
      can('resend', 'User')
      can('reset', 'User')
      can('resetMfa', 'User', { systemRole: { $in: ['Client Admin', 'Client Standard'] } })

      can('read', 'ReleaseNote')
      can('read', 'Resource')
      can('read', 'TermsOfService')

      can('read', 'Alert')

      can('create', 'Subscription')
      can('read', 'Subscription')
      can('update', 'Subscription')
      can('pause', 'Subscription')
      can('delete', 'Subscription')
      can('read', 'SubscriptionMember')
      can('pause', 'SubscriptionMember')
      can('delete', 'SubscriptionMember')

      can('read', 'Dashboard')
      can('create', 'Bookmark')
      can('read', 'Bookmark')
      can('delete', 'Bookmark')

      can('read', 'PlanReviewDownload')

      break
    case 'Client Standard':
      can('read', 'ReleaseNote')
      can('read', 'Resource')
      can('read', 'TermsOfService')
      if (viewDl) {
        can('read', 'Setting')
        can('read', 'DataLogicSettings')
        can('read', 'TemplateSetting')
        can('read', 'ClientCustomer')

        can('create', 'TemplateSetting')
        can('update', 'TemplateSetting')
        can('delete', 'TemplateSetting')

        can('create', 'ClientCustomer')
        can('update', 'ClientCustomer')
        can('delete', 'ClientCustomer')

        can('create', 'FileUpload')
        can('update', 'FileUpload')
        can('delete', 'FileUpload')
        can('read', 'FileUpload')
      }

      if (viewBL) {
        can('create', 'FileRunRequest')
        can('delete', 'FileRunRequest')
      }

      can('read', 'Alert')

      can('create', 'Subscription')
      can('read', 'Subscription')
      can('update', 'Subscription')
      can('pause', 'Subscription')
      can('delete', 'Subscription')
      can('read', 'SubscriptionMember')
      can('pause', 'SubscriptionMember')
      can('delete', 'SubscriptionMember')

      can('read', 'Dashboard')
      can('create', 'Bookmark')
      can('read', 'Bookmark')
      can('delete', 'Bookmark')

      can('read', 'PlanReviewDownload')

      break
    default:
      cannot('manage', 'all')
      break
  }

  return build()
}
