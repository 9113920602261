import axios from 'axios'
import { cookie } from '@utils'

const axiosGet = (url) => {
  if (url.indexOf('http') === -1) {
    url = `${location.origin}/api/${url}`
  }
  const options = {
    headers: {}
  }
  const csrfCookie = cookie.get('csrfCookie')

  if (csrfCookie) {
    options.headers['x-csrf-token'] = csrfCookie
  } else {
    return null
  }

  options.withCredentials = true

  let method = 'GET'

  if (options.method) {
    method = options.method
  }

  return axios.get(url, method, options)
}

export default axiosGet
