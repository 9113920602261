import React, { cloneElement, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Page } from '@lib'
import { Loader } from '@shared'
import { getDefaultDashboardPath } from '@utils'

import authGql from '@graphql/queries/auth'
import mfaGql from '@graphql/queries/mfa'

import bgImage from '@images/networkingBG.png'
import { useQuery } from '@apollo/client'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'

dayjs.extend(utc)
dayjs.extend(relativeTime)

const AuthenticationWrapper = ({ children }) => {
  const mfaToken = localStorage.getItem('MfaToken')

  if (mfaToken == null) {
    return (
      <Navigate replace to="/login" />
    )
  }

  const authQuery = useQuery(authGql)
  const mfaQuery = useQuery(mfaGql, {
    variables: { mfaToken }
  })

  const user = authQuery?.data?.auth
  const mfaUser = mfaQuery

  const [navPath, setNavPath] = useState()
  useEffect(() => {
    if (user && user.systemRole) {
      getDefaultDashboardPath(user).then(u => {
        setNavPath(u)
      })
    }
  }, [user])

  if (user && user.systemRole) {
    if (navPath) {
      return (
        <Navigate replace to={navPath}/>
      )
    } else {
      return <Loader />
    }
  }

  if (authQuery.loading || mfaQuery.loading) {
    return (
      <Loader />
    )
  }

  return (
    <Page
      name='login'
      className='login'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle mfa-content'>
          {cloneElement(children, { user, mfaUser })}
        </div>
      </div>
    </Page>
  )
}

AuthenticationWrapper.propTypes = {
  children: PropTypes.node
}

export default AuthenticationWrapper
