import client from '../client.js'
import planReviewDownloadsGql from '../queries/plan-review-downloads.gql'
import createPlanReviewDownloadGql from '../mutations/create-plan-review-download.gql'

const createPlanReviewDownload = (input) => {
  return client.mutate({
    mutation: createPlanReviewDownloadGql,
    variables: input,
    update: (store, { data }) => {
      if (data.planReviewDownload) {
        // update to clients cache
        store.writeQuery({
          query: planReviewDownloadsGql,
          data: {
            planReviewDownloads: [...data.planReviewDownloads, data.planReviewDownload]
          }
        })
      }
    }
  })
}

export default createPlanReviewDownload
