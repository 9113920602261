import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button } from '@lib'
import { Input } from '@shared/Form'
import { axiosGet } from '@utils'
import axios from 'axios'
import PropTypes from 'prop-types'
import config from '@config'
import Swal from 'sweetalert2'
import Modal from '@lib/Modal'
import createPlanReviewDownload from '@graphql/mutators/create-plan-review-download'

const ExportSelector = ({ showModal, setShowModal }) => {
  const viz = document.getElementById('tableauViz')
  const [sheetsSelected, setSheetsSelected] = useState([])
  const [selected, setSelected] = useState([])
  const [sheetsContainerDetails, setSheetsContainerDetails] = useState([])
  const [favorites, setFavorites] = useState([])
  const scrollRef = useRef(null)

  const handleClickSheet = (sheet) => {
    if (selected.includes(sheet.index)) {
      setSelected(selected.filter((x) => x !== sheet.index))
      setSheetsSelected(sheetsSelected.filter((x) => x.index !== sheet.index))
    } else {
      setSelected([...selected, sheet.index])
      setSheetsSelected([...sheetsSelected, sheet])
    }
  }

  const handleSelectAll = (x) => {
    if (x) {
      setSelected(viz.workbook._workbookImpl.publishedSheetsInfo.map((sheet) => sheet.index))
      setSheetsSelected(viz.workbook._workbookImpl.publishedSheetsInfo)
    } else {
      setSelected([])
      setSheetsSelected([])
    }
  }

  const handleAddFavorite = (sheetName) => {
    if (favorites.includes(sheetName)) {
      setFavorites(favorites.filter((x) => x !== sheetName))
    } else {
      setFavorites([...favorites, sheetName])
    }
  }

  const handleDownload = async () => {
    let params = []
    let filters = []
    const viz = document.getElementById('tableauViz')
    await viz.workbook.activateSheetAsync(0) // redirect to param page
    const parameters = await viz.workbook.getParametersAsync()
    const filter = await viz.workbook.activeSheet.getFiltersAsync()

    params = parameters.map((param) => {
      return {
        name: param.name,
        value: param.currentValue.value
      }
    })

    filters = filter.map((filter) => {
      return {
        name: filter.fieldName,
        value: filter.appliedValues,
        isAllSelected: filter.isAllSelected
      }
    })

    createPlanReviewDownload({
      name: viz.workbook.name,
      filter: JSON.stringify({
        params,
        filters
      }),
      sheets: JSON.stringify(sheetsSelected.map((sheet) => sheet.index))
    }).then(({ data }) => {
      if (data.createPlanReviewDownload) {
        Swal.fire({
          icon: 'success',
          text: 'Processing your download request',
          timer: 3000,
          showConfirmButton: false
        })
        setShowModal(false)
        setSheetsSelected([])
        setSelected([])
        setFavorites([])
      }
    })
  }

  const handleScrollClick = (direction) => {
    if (scrollRef.current && direction === 'right') {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' })
    } else if (scrollRef.current && direction === 'left') {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' })
    }
  }

  if (viz) {
    const sheetsLength = viz.workbook._workbookImpl.publishedSheetsInfo.length
    return (
      <Fragment>
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <div className='modal-header'>
            Customize your Plan Review
          </div>
          <div className='modal-body'>
            <div className='sheets-container'>
              <div className='sheet-actions' >
                <div className='sheets-action-radio'>
                  <Button
                    text='Select All'
                    onClick={() => handleSelectAll(true)}/>
                  <Button
                    text='Clear All'
                    onClick={() => handleSelectAll(false)}
                  />
                </div>
                <div className='sheets-selected'>
                  {sheetsSelected.length} of {sheetsLength} selected
                </div>
              </div>
              <div className='sheet-item-container' ref={scrollRef}>
                {viz.workbook._workbookImpl.publishedSheetsInfo.map((sheet, index) => {
                  return (
                    <Fragment key={index}>
                      <div className='sheet-item' >
                        <div className='sheet-item-thumbnail' onClick={() => handleClickSheet(sheet)}>
                          <div className={`sheet-select-status ${selected.includes(sheet.index) ? 'selected' : ''}`}>
                            <i className='fas fa-check'></i>
                          </div>
                        </div>
                        <div className='sheet-item-name'>
                          <div onClick={() => handleClickSheet(sheet)}>
                            {sheet._name}
                          </div>
                          <div className='sheet-favorite' onClick={() => handleAddFavorite(sheet._name)}>
                            <div className={`sheet-favorite-button ${favorites.includes(sheet._name) ? 'favorite' : ''}`} >
                              <div className='sheet-favorite-status'/>
                            </div>
                            <div>
                              Favorite
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='divider'></div>
                    </Fragment>
                  )
                })}
              </div>
              <div className='scroll-right-button' onClick={() => handleScrollClick('right')}>
                <i className='far fa-angle-right fa-2x'/>
              </div>
              <div className='scroll-left-button' onClick={() => handleScrollClick('left')}>
                <i className='far fa-angle-left fa-2x'/>
              </div>

              <Button
                text='Download'
                className='download-button'
                onClick={() => handleDownload()}
                icon='fas fa-arrow-down'
                iconAlign='right'
              />
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}

const PlanReview = ({ tableauDialogType, user, loadPlanReviewModal }) => {
  const [input, setInput] = useState({
    image: null,
    client: null
  })
  const [showExportSelector, setShowExportSelector] = useState(false)

  const openDownloadModal = () => {
    const viz = document.getElementById('tableauViz')
    viz.displayDialogAsync(tableauDialogType.ExportPowerPoint)
  }

  const isPlanReview = window.location.href.toLowerCase().includes('planreview')

  useEffect(() => {
    if (loadPlanReviewModal) {
      const viz = document.getElementById('tableauViz')
      document.getElementById('plan-review-actions-container').style.maxWidth = viz.workbook.activeSheet.size.maxSize.width + 'px'
    }
  }, [loadPlanReviewModal])

  const uploadClientLogo = async () => {
    if (input.image instanceof File) {
      let bucketEnv = 'dev'
      if (config.APP_ENV === 'prod' || config.APP_ENV === 'production') {
        bucketEnv = 'prod'
      } else if (config.APP_ENV === 'staging' || config.APP_ENV === 'stage') {
        bucketEnv = 'stage'
      }
      const bucket = `xevant-${bucketEnv}-alcatraz-be-uploads-us-west-2`
      const key = encodeURIComponent('planReview/' + user.client.name)
      await axiosGet(`s3/signed-url/${bucket}/${key}`).then(async e => {
        const uploadUrl = e.data.url
        axios.put(uploadUrl, input.image)
        Swal.fire({
          text: 'Client logo uploaded successfully',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000
        })
      })
    }
  }

  return (
    <>
    { (isPlanReview && loadPlanReviewModal) && (
      <div id='plan-review-actions-container'>
      <div className='container'>
        {/* <Input
            label='File'
            type='draganddrop'
            value={input.image}
            onChange={(v) => setInput({ ...input, image: v.target.files[0] })}
            accept='.png, .jpg, .jpeg'
            logoDirectory='client-logo'
            className='logo-upload'
          />
        <Button
          className='standard primary'
          text='Upload'
          onClick={() => uploadClientLogo()}
          upperCase={false}
          icon='fas fa-down-to-line fa-rotate-180'
        /> */}
      </div>
      <div className='container'>
      <Button
        className='standard primary'
        text='Download Powerpoint'
        onClick={() => setShowExportSelector(true)}
        upperCase={false}
      />
      </div>
      <ExportSelector showModal={showExportSelector} setShowModal={setShowExportSelector}/>
    </div>)
    }
  </>
  )
}

ExportSelector.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
}

PlanReview.propTypes = {
  tableauDialogType: PropTypes.object,
  user: PropTypes.object,
  loadPlanReviewModal: PropTypes.bool
}

PlanReview.defaultProps = {
  tableauDialogType: {},
  user: {},
  loadPlanReviewModal: false
}

export default PlanReview
