import React, { Fragment, useState, useEffect } from 'react'
import * as tableau from '@utils/tableau-embed-api.js'
import Modal from '@lib/Modal'
import client from '@graphql/client'

import trustedTicketGql from '@graphql/queries/trusted-ticket'
import tableauServerSettingGql from '@graphql/queries/tableau-server-setting'
import updatePlanReviewContainer from '@graphql/mutators/update-plan-review-container'
import { Input } from '@shared/Form'
import { Button } from '@lib'

const { TableauViz, TableauEventType } = tableau

const SyncPlanReview = ({ show, setShow, user, vizRef }) => {
  const [multiplePlanReviews, setMultiplePlanReviews] = useState(false)
  const [workbooks, setWorkbooks] = useState([])
  const [isSyncing, setIsSyncing] = useState(false)
  const [selectedWorkbook, setSelectedWorkbook] = useState(null)
  let tableauServer = null

  async function getTrustedTicket () {
    return await client.query({
      query: trustedTicketGql,
      fetchPolicy: 'no-cache'
    }).then(({ data }) => {
      const trustedTicket = data.trustedTicket
      loadTableauViz(trustedTicket, selectedWorkbook.contentUrl, selectedWorkbook.viewUrlName)
      return trustedTicket
    })
  }

  async function getTableauServerSetting () {
    await client.query({
      query: tableauServerSettingGql,
      fetchPolicy: 'no-cache'
    }).then(async ({ data }) => {
      try {
        tableauServer = data.tableauServerSetting?.settingValue
      } catch (err) {}
    })
  }

  const loadTableauViz = (ticket, workbookName, viewName) => {
    const handleFirstInteractive = async () => {
      const viz = document.getElementById('tableauViz')
      const workbook = viz.workbook
      const sheets = workbook._workbookImpl.publishedSheetsInfo

      const containerInfo = []
      const tabNavs = JSON.parse(localStorage.getItem('tabNavs'))
      const planReviewFolder = tabNavs[0].children.find(wb => wb.name === 'Plan Review')
      const planReviewWb = planReviewFolder.workbooks.find(wb => wb.contentUrl === workbookName)

      for (const sheet of sheets) {
        if (sheet.index !== viz.workbook._workbookImpl._activeSheetImpl.index) {
          await viz.workbook.activateSheetAsync(sheet.index)
        }
        const viewId = planReviewWb.views.find(view => view.name === sheet.name).id
        const zones = viz.workbook._workbookImpl._activeSheetImpl._zones.filter(zone => zone.name.toLowerCase().includes('container'))
        const size = viz.workbook._workbookImpl._activeSheetImpl.size.maxSize
        containerInfo.push({ index:viz.workbook._workbookImpl._activeSheetImpl.index, zones, viewId, title: sheet.name, size })
      }

      const containers = JSON.stringify(containerInfo)

      updatePlanReviewContainer({
        name: viz.workbook.name,
        isSynced: true,
        containers
      }).then(({data}) => {
        if (data.updatePlanReviewContainer) {
          document.getElementById('tableauViz').remove()
          setIsSyncing(false)
        } 
      })
    }

    if (vizRef.current && ticket) {
      const viz = new TableauViz()
      viz.id = 'tableauViz'
      viz.src = `https://${tableauServer}/trusted/` + ticket + ('/t/' + user.client.contentUrl) + '/views/' + workbookName + '/' + viewName
      document.getElementById('vizWrapperSettings').appendChild(viz)
      viz.addEventListener(TableauEventType.FirstInteractive, handleFirstInteractive)
    }
  }

  useEffect(() => {
    const tabNavs = JSON.parse(localStorage.getItem('tabNavs'))
    const planReviewFolder = tabNavs[0].children.find(wb => wb.name === 'Plan Review')
    setWorkbooks(planReviewFolder.workbooks.map(wb => ({
      text: wb.name,
      value: {
        contentUrl: wb.contentUrl,
        viewUrlName: wb.defaultView.viewUrlName
      }
    })))
  }, [])

  const handleSync = async () => {
    setIsSyncing(true)
    await getTableauServerSetting()
    await getTrustedTicket()
  }

  return (
    <Fragment>
      <Modal show={show} onClose={() => setShow(false)}>
        <div className='sync-status'>
          {!isSyncing && 
          <>
            <Input 
              label='Select Plan Review'
              type='select'
              options={workbooks}
              value={selectedWorkbook}
              onChange={(v) => setSelectedWorkbook(v)}
            /> 
            <Button 
              onClick={() => handleSync()}
              disabled={isSyncing}
              text='Sync'
            />
          </> }
          {isSyncing && <><h3>Syncing plan reviews...</h3> <span><i className='fas fa-circle-notch fa-spin fa-3x'/></span></>}
        </div>
      </Modal>
    </Fragment>
  )
}

export default SyncPlanReview