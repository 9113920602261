import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon, Loaders } from '@lib'
import ClickOutside from '@lib/ClickOutside'

const MultiSelectInput = ({ value, placeholder, searchPlaceholder, options, onChange, filterCollapse, loading, disabled, hasSelectAll, selectedAll, multipleValueText }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [currentValue, setCurrentValue] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [filteredResults, setFilteredResults] = useState(options)
  const [allSelected, setAllSelected] = useState(false)
  const [lastFilteredResults, setLastFilteredResults] = useState()
  const selectedOptions = []

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (filterCollapse) {
      setCollapsed(true)
    }
  }, [filterCollapse])

  useEffect(() => {
    setFilteredResults(options)
  }, [loading, options])

  useEffect(() => {
    if (selectedAll) {
      const allValues = options.map(option => option.value)
      setCurrentValue(allValues)
    }
  }, [selectedAll])

  useEffect(() => {
    if (options?.length > 0 && options?.length === selectedOptions?.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }, [options, selectedOptions])

  let optionsWithState = options.map(option => {
    if (currentValue.includes(option.value)) {
      selectedOptions.push(option)
      option.selected = true
    } else {
      option.selected = false
    }

    return option
  })
  optionsWithState = (lastFilteredResults?.length > 0 ? optionsWithState.filter(o => lastFilteredResults?.find(fr => fr.value === o.value)) : optionsWithState)

  const handleClickAll = () => {
    setAllSelected((allSelected) => !allSelected)
    if (allSelected) { // unchecking the box
      setCurrentValue([])
      onChange([])
    } else {
      const selectAllOptions = options.map(option => option.value)
      setCurrentValue(selectAllOptions)
      onChange(selectAllOptions)
    }
  }

  const handleToggleCollapse = () => {
    if (!disabled) {
      setCollapsed(!collapsed)
    }
  }

  const autoOptions = []
  const handleSelect = (option) => {
    autoOptions.push(option.value)
    const newState = [...currentValue]

    if (autoOptions.length === 1) {
      setTimeout(function () {
        autoOptions.forEach(ao => {
          if (newState.includes(ao)) {
            newState.splice(newState.indexOf(ao), 1)
          } else {
            newState.push(ao)
          }
        })

        onChange(newState)
        setCurrentValue(newState)
      }, 50)
    }
  }

  const handleClickOutside = () => {
    if (collapsed) {
      setCollapsed(false)
    }
  }

  const handleSearch = (searchValue) => {
    if (searchValue !== '') {
      const filteredData = options.filter((item) => {
        return item.text.toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredResults(filteredData)
      setLastFilteredResults(filteredData)
    } else {
      setFilteredResults(options)
      setLastFilteredResults([])
    }
  }

  return (
    <ClickOutside onClick={handleClickOutside}>
      <div className='multiselect-input'>
        {loading && <div className='input-overlay'><Loaders.LoaderA /></div>}
        <div
          className='multiselect-values'
          onClick={handleToggleCollapse}
        >
          <ul>
            {options?.length > 0 && options?.length === selectedOptions?.length
              ? <li>
                  <span className={`text ${disabled ? 'disabled' : ''}`}>
                    All
                  </span>
                </li>
              : multipleValueText
                ? <>
                {selectedOptions.length > 1
                  ? <li>
                      <span className={`text ${disabled ? 'disabled' : ''}`}>
                        MULTIPLE VALUES
                      </span>
                    </li>
                  : <>
                    {selectedOptions.map((option, key) => (
                      <li key={key}>
                        <span className={`text ${disabled ? 'disabled' : ''}`}>
                          {option.text}
                        </span>
                      </li>
                    ))}
                  </>
                }
                </>
                : <>
                    {selectedOptions.map((option, key) => (
                      <li key={key}>
                        <span className={`text ${disabled ? 'disabled' : ''}`}>
                        {option.text}
                      </span>
                      </li>
                    ))}
                  </>
                }
          </ul>
          { !collapsed && currentValue.length <= 0
            ? <>
                {placeholder}
              </>
            : <>
              {currentValue.length > 0
                ? <></>
                : <>
                  {placeholder}
                  </>
              }
              </>
          }
          <span className={`dropdown-icon ${collapsed ? 'fas fa-caret-up' : 'fas fa-caret-down'}`}></span>
        </div>

        {!!optionsWithState.length && (
          <div className={classNames('multiselect-options', { collapsed })}>

            <div className='active-dropdown-select search-multiselect'>
              <span className='far fa-search'></span>
              <input className='search-input' onChange={(e) => handleSearch(e.target.value)} placeholder={searchPlaceholder ? `${searchPlaceholder}` : 'Search Options'} type='text' disabled={disabled}/>
            </div>

            <ul>
              { hasSelectAll &&
                <li
                onClick={handleClickAll}
                >
                  <Icon className={`icon ${allSelected ? 'fas fa-check-square' : 'fal fa-square'}`}/>
                  <span className='text'>
                    All
                  </span>
                </li>
              }

              {optionsWithState.map((option, key) => filteredResults.find(f => f.value === option.value) && (
                <li
                  key={key}
                  className={classNames({ selected: option.selected })}
                  onClick={() => handleSelect(option)}
                >
                  <Icon
                    className={`icon ${option.selected ? 'fas fa-check-square' : 'fal fa-square'}`}
                  />
                  <span className='text'>
                    {option.text}
                    {option.id && (
                      <Icon
                        id={option.id}
                        className='icon fas fa-question-circle'
                      />
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickOutside>

  )
}

MultiSelectInput.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  filterCollapse: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
  selectedAll: PropTypes.bool,
  multipleValueText: PropTypes.bool
}

MultiSelectInput.defaultProps = {
  value: [],
  placeholder: '',
  options: [],
  disabled: false,
  filterCollapse: false,
  onChange: () => {}, // This is intentional
  hasSelectAll: false,
  selectedAll: false,
  multipleValueText: false
}

export default MultiSelectInput
