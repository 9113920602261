import client from '../client.js'
import updatePlanReviewContainerGql from '../mutations/update-plan-review-container.gql'

const updatePlanReviewContainer = (input) => {
  return client.mutate({
    mutation: updatePlanReviewContainerGql,
    variables: input
  })
}

export default updatePlanReviewContainer