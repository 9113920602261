import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Loaders } from '@lib'

const StyledSelectInput = ({ value, options, placeholder, disabled, onChange, loading }) => {
  const [isActive, setIsActive] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [filteredResults, setFilteredResults] = useState([])
  const ref = useRef()
  let currentText = null

  useEffect(() => {
    const handleDropdownClose = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setIsActive(false)
      }
    }
    document.addEventListener('mousedown', handleDropdownClose)
  }, [ref])

  const getCurrentText = (value) => {
    currentText = options?.find(o => o.value === value)?.text
  }

  const handleChange = (v) => {
    onChange(v.value)
    setIsActive(false)
    getCurrentText(v.value)
  }

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
      const filteredData = options.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLocaleLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(options)
    }
  }

  const handleActive = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsActive(!isActive)
    setSearchInput('')
  }

  getCurrentText(value)

  return (
    <>
    {loading && <div className='input-overlay'><Loaders.LoaderA /></div>}

      <div className='dropdown' ref={ref}>

      {options.length < 12

        ? <>
        {/* normal select */}
      { disabled || loading
        ? <>
            <div className='disabled-dropdown dropdown-btn' >
              {currentText || placeholder}
              <span className={isActive ? 'fas fa-caret-up' : 'dropdown-icon fas fa-caret-down'}></span>
            </div>
          </>
        : <>

        <div className='dropdown-btn' onClick={(e) => setIsActive(!isActive)}>
        { isActive
          ? <>
              {placeholder}
            </>
          : <>
            {currentText
              ? <>{currentText}</>
              : <>{placeholder}</>
            }
            </>
          }
          <span className={isActive ? 'dropdown-icon fas fa-caret-up' : 'dropdown-icon fas fa-caret-down'}></span>
          </div>
          { isActive
            ? <div
              className='dropdown-content'
              onChange={handleChange}
              disabled={disabled || loading} >
                {options.map((option, key) => (
                  <div
                    className='dropdown-item'
                    key={key}
                    value={option.value}
                    onClick={(v) => handleChange(option)}
                  >
                    {option.text}
                  </div>
                ))}
              </div>
            : <></>
          }
        </>
      }
      </>
        : <>
          {/* search select */}
            {disabled
              ? <>
                  <div className='disabled-dropdown dropdown-btn' >
                    {currentText || placeholder}
                  <span className={isActive ? 'dropdown-icon fas fa-caret-up' : 'dropdown-icon fas fa-caret-down'}></span>
                  </div>
                </>
              : <>
            { isActive
              ? <>
                <div className='active-dropdown-select'>
                  <span className='far fa-search'></span>
                  <input className='search-input' onChange={(e) => handleSearch(e.target.value)} placeholder={'Search Options'} type='text' />
                </div>
                </>
              : <div className='dropdown-btn' onClick={(e) => handleActive(e)}>

              {currentText
                ? <>{currentText}
                    <span className={isActive ? 'dropdown-icon fas fa-caret-up' : 'dropdown-icon fas fa-caret-down'}></span> </>
                : <>
                    {placeholder}
                    <span className={isActive ? 'dropdown-icon fas fa-caret-up' : 'dropdown-icon fas fa-caret-down'}></span>
                  </>
              }
          </div>
        }

      { isActive
        ? <>
          { searchInput.length > 1
            ? <div
                className='dropdown-content'
                onChange={handleChange}
                disabled={disabled}
              >
                {filteredResults.map((option, key) => (
                  <>
                    <div
                      className='dropdown-item'
                      value={option.value}
                      key={key}
                      onClick={(v) => handleChange(option)}
                    >
                      {option.text}
                    </div>
                  </>
                ))}
              </div>

            : <div
                className='dropdown-content'
                onChange={handleChange}
                disabled={disabled}
              >
                {options.map((option, key) => (
                  <div
                    className='dropdown-item'
                    value={option.value}
                    key={key}
                    onClick={(v) => handleChange(option)}
                  >
                    {option.text}
                  </div>
                ))}
              </div>
            }

          </>
        : <></>
      }
      </>
      }
      </>
      }
      </div>
    </>
  )
}

StyledSelectInput.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  loading: PropTypes.bool
}

StyledSelectInput.defaultProps = {
  value: '',
  options: [],
  placeholder: '',
  disabled: false,
  onChange: (e) => {} // This is intentional
}

export default StyledSelectInput
