import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Dashboard from '@wrappers/Dashboard'
import { Title, Page } from '@lib'
import logoImage from '@images/xevant-logo-horizontal.png'
import { TosText, PrivacyPolicyText } from './tos.js'

const Content = ({ user }) => {
  const [sections, setSections] = useState([])

  const scrollToSection = (v) => {
    let sectionH
    if (v.target.localName === 'h5' || v.target.localName === 'span') {
      sectionH = Array.from(document.querySelectorAll('.doc-box h1')).filter(el => {
        return el.innerHTML === v.target.innerHTML
      })[0]
    } else {
      sectionH = Array.from(document.querySelectorAll('.doc-box h2')).filter(el => {
        return el.innerHTML === v.target.innerHTML
      })[0]
    }

    const topPos = sectionH.offsetTop
    const scrollDiv = document.querySelector('.doc-box')
    scrollDiv.scrollTop = topPos
  }

  const watchScroll = () => {
    document.querySelectorAll('.doc-box h2').forEach(h => {
      if (h.offsetTop - 50 < document.querySelector('.doc-box').scrollTop) {
        if (document.querySelector('.left-content a.active')) {
          document.querySelector('.left-content a.active').classList.remove('active')
        }
        const sLink = Array.from(document.querySelectorAll('.left-content a')).filter(el => {
          return el.innerHTML === h.innerHTML
        })[0]
        sLink.classList.add('active')
      }
    })
  }

  useEffect(() => {
    const sectionsH = document.querySelectorAll('.doc-box h2')
    if (sectionsH.length > 0) {
      const sectionsArr = []
      sectionsH.forEach(h => {
        sectionsArr.push(h.innerHTML)
      })
      setSections(sectionsArr)
    }
  }, [document.querySelectorAll('.doc-box h2').length])

  return (
    <Fragment>
      <Title text='Terms of Service'/>
      <Page
        name='resources'
        className='resources'
      >
        <div className='content'>
          <div className='middle tos-mid'>
            <div className='left-info'>
              <div className='login-logo-left'>
                {!user.client.isWhiteLabel && (
                  <img src={logoImage} alt='logo'/>
                )}
              </div>

              <div className='left-content'>
                <h5 onClick={(v) => scrollToSection(v)}>Terms of Service</h5>
                {sections.length > 0 &&
                  sections.map((x, key) =>
                  <div key={key}>
                    {x === 'Contents' &&
                      <h5 className='topMargin' onClick={(v) => scrollToSection(v)}>Privacy Policy</h5>
                    }
                    <a onClick={(v) => scrollToSection(v)}>{x}</a>
                  </div>
                  )
                }
              </div>
            </div>
            <div className='right-form'>
              <div id='right-form-header'>
                <span>Last Modified: 2023-01-25</span>
              </div>
              <div className='doc-box' onScroll={() => watchScroll()}>
                <h1>Terms of Service</h1>
                <TosText
                  scrollToSection={scrollToSection}
                  user={user}
                />
                <PrivacyPolicyText
                  scrollToSection={scrollToSection}
                />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </Fragment>
  )
}

const Tos = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

export default Tos
