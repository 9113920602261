import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { Group, Input } from '@shared/Form'
import { axiosGet } from '@utils'
import client from '@graphql/client'
import QRCode from 'qrcode'
import PropTypes from 'prop-types'
import AuthenticationWrapper from '@shared/AuthenticationWrapper'
import { VerifyInner } from './verify.js'
import logoImage from '@images/xevant-logo-horizontal.png'
import clientLogoGql from '@graphql/queries/client-logo'

const Content = ({ mfaUser }) => {
  let qrImage = ''
  let mfaKey = ''
  let tempUser = null
  const [showKey, setShowKey] = useState(false)

  if (mfaUser) {
    try {
      tempUser = mfaUser.data.mfa
      if ((tempUser.mfaMethod !== null || tempUser.mfaMethod !== '') && tempUser.isMfaScanned) {
        return (
          <Navigate replace to='/authenticate/verify' />
        )
      }

      const secret = JSON.parse(tempUser.mfaKey)
      if ((secret.base32).length > 0) {
        secret.base32 = (secret.base32).match(/.{1,4}/g)
      }
      mfaKey = (secret.base32).join(' ')
      QRCode.toDataURL(secret.otpauth_url, function (_err, dataUrl) {
        qrImage = dataUrl
      })
    } catch (err) {
      console.log(err)
    }
  }

  const [clientLogo, setClientLogo] = useState('')
  useEffect(() => {
    if (!clientLogo) {
      client.query({
        query: clientLogoGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientLogo) {
          axiosGet(`s3/client-logo/${data.clientLogo.logoPath}`).then(({ data }) => {
            setClientLogo(data.url)
          })
        } else if (window.location.hostname.includes('localhost') || window.location.hostname === 'app.xevant.com') {
          setClientLogo(logoImage)
        }
      })
    }
  }, [clientLogo])

  return (
  <>
  {/* <div className='mfa-scan-wrap'> */}
    <div className='left-info mfa-info'>
      <div className='login-logo-left'>
        <img src={clientLogo} />
      </div>
      <div className='left-content'>
        <h2>Two-Factor Authentication (2FA)</h2>
        <p className='big-text'>
          With <span className='highlight-blue'>two-factor authentication (2FA)</span> enabled,
          you make it harder for unauthorized persons to
          access your protected information by adding an extra layer of security.
        </p>
      </div>
      {window.location.hostname.includes('xevant') && (
        <div className='copyright'>
          Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
        </div>
      )}
    </div>
    <div className='right-form'>
      <form
        onSubmit={(evt) => evt.preventDefault()}
        noValidate
      >

        <h2>Enable Two-Factor Authentication</h2>

        {/* <div className='big-text'> */}
          <p className='big-text'>Download on your mobile device a free authenticator app and
            {showKey ? ' enter the secret key below, or ' : ' scan the QR code below, or '}
            <span onClick={() => setShowKey(!showKey) } className='text-action'>{showKey ? 'scan a QR code instead.' : 'enter your secret key manually.'}</span>
            </p>
        {/* </div> */}

        { showKey
          ? (
            <Group className='mfa-key-group'>
              <Input
                className='login-input'
                type='textarea'
                disabled='true'
                value={mfaKey}
                dataAttrId="mfa_scan_key_field"
              />
            </Group>
            )
          : (
            <div className='mfa-scan-qr'>
              <img data-attribute-id="mfa_scan_qr_img" src={qrImage} />
            </div>
            )
        }

        <div className='scan-verify-div'>
          <p className='scan-verify-text'>Enter below the 6-digit code you see in the app.</p>
          <VerifyInner/>
        </div>
      </form>
    </div>
    </>
  )
}
Content.propTypes = {
  user: PropTypes.object,
  mfaUser: PropTypes.object
}

const Scan = () => {
  return (
    <AuthenticationWrapper>
      <Content />
    </AuthenticationWrapper>
  )
}

export default Scan
