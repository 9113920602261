import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const Tooltip = ({ anchorId, content, className, place, ...rest }) => {
  return (
    <>
    { place
      ? <ReactTooltip
          anchorId={anchorId}
          content={content}
          place={place}
          className={classNames('custom-tooltip', className)}
          classNameArrow={classNames('custom-tooltip-arrow', className)}
          variant='info'
          offset={20}

          {...rest}
        />
      : <ReactTooltip
          anchorId={anchorId}
          content={content}
          place='bottom'
          className={classNames('custom-tooltip', className)}
          classNameArrow={classNames('custom-tooltip-arrow', className)}
          variant='info'
          {...rest}
        />
    }
    </>
  )
}

Tooltip.propTypes = {
  anchorId: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  place: PropTypes.string
}

export default Tooltip
