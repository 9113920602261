import React, { Fragment, useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { Page, Button, Icon } from '@lib'
import { Group, FormError } from '@shared/Form'
import { array, object, axiosGet } from '@utils'

import Swal from 'sweetalert2'
import { PasswordValidationChecklist } from '@shared/index.js'
import validateLogin from '@graphql/mutators/validate-login'
import clientLogoGql from '@graphql/queries/client-logo'
import client from '@graphql/client'

import logoImage from '@images/xevant-logo-horizontal.png'
import bgImage from '@images/networkingBG.png'

import createPasswordGql from '@graphql/queries/create-password'
import createPasswordMutationGql from '@graphql/mutations/create-password'
import { isValidPassword } from '@utils/validate-password'

const CreatePassword = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    password: ''
  })
  const [loading, setLoading] = useState(true)
  const [loaderState, setLoaderState] = useState({
    submit: false
  })
  const [createPasswordQuery, setCreatePasswordQuery] = useState()

  const [createPassword] = useMutation(createPasswordMutationGql)

  useEffect(() => {
    if (loading) {
      client.query({
        query: createPasswordGql,
        variables: {
          token
        }
      }).then(({ data }) => {
        setCreatePasswordQuery({
          ...data.createPassword,
          validToken: !data.createPassword?.errors?.find(e => e.path === 'token'),
          pageMessage: data.createPassword?.errors?.[0]?.message
        })
        setLoading(false)
      })
    }
  }, [loading])

  const [clientLogo, setClientLogo] = useState('')
  useEffect(() => {
    if (!clientLogo) {
      client.query({
        query: clientLogoGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientLogo) {
          axiosGet(`s3/client-logo/${data.clientLogo.logoPath}`).then(({ data }) => {
            setClientLogo(data.url)
          })
        } else if (window.location.hostname.includes('localhost') || window.location.hostname === 'app.xevant.com') {
          setClientLogo(logoImage)
        }
      })
    }
  }, [clientLogo])

  const handleShowPassword = () => {
    const passInput = document.querySelector('.new-password input')
    if (passInput.type === 'password') {
      document.querySelector('.new-password i').classList.add('fa-eye-slash')
      passInput.type = 'text'
    } else {
      document.querySelector('.new-password i').classList.remove('fa-eye-slash')
      passInput.type = 'password'
    }
  }

  const handleInputChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const state = { ...input }
    state[name] = value
    setInput(state)
  }

  const handleSubmit = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}

    if (!input.password) {
      feErrors.password = [{ message: 'password required' }]
    } else {
      if (!isValidPassword(input.password)) {
        feErrors.password = [{ message: 'The password does not meet the criteria below' }]
      }
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      createPassword({
        variables: {
          token,
          password: input.password
        }
      }).then(({ data, errors, extensions }) => {
        if (data.createPassword) {
          // navigate('/login')

          validateLogin({ username: data.createPassword.username, password: input.password }).then(({ data }) => {
            if (data.validateLogin) {
              navigate('/authenticate')
            } else {
              Swal.fire({
                text: 'Error on login',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
              })
            }
          })

          Swal.fire({
            text: 'Account activated!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
        } else if (!errors && extensions && extensions.errors) {
          setLoaderState(prevState => ({ ...prevState, submit: false }))
          setInputErrors(array.groupBy(extensions.errors, 'path'))
        }
      })
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  if (loading) {
    return null
  }

  return (
    <Page
      name='login'
      className='login'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle'>
          <div className='left-info'>
            <div className='login-logo-left'>
              <img src={clientLogo} />
            </div>

            {createPasswordQuery.ok && createPasswordQuery.validToken && (
              <div className='left-content create-password-container'>
                <>
                  <h2>95% of cybersecurity issues <span>can be traced to human error.</span></h2>
                  <p>The Global Risk Report 2022, <span>World Economic Forum</span></p>
                </>
              </div>
            )}

            {!createPasswordQuery.ok && createPasswordQuery.validToken && (
              <div className='left-content invalid-left-content'>
                <div className='left-content-icon'>
                  <Icon className="far fa-times-octagon"/>
                </div>
              </div>
            )}

            {!createPasswordQuery.ok && !createPasswordQuery.validToken && (
              <div className='left-content invalid-left-content'>
                <div className='left-content-icon'>
                  <Icon className="far fa-times-octagon"/>
                </div>
              </div>
            )}

            {window.location.hostname.includes('xevant') && (
              <div className='copyright'>
                Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
              </div>
            )}
          </div>

          {!createPasswordQuery.ok && !createPasswordQuery.validToken && (
            <div className='right-form invalid-right-form'>
              <div className='invalid'>
                <br/>
                <h1>Activation link invalid</h1>
                <p className='big-text'>The link you clicked is either expired or invalid.</p>
                <br />
                <p className='big-text'>Please contact your client success representative for assistance.</p>
              </div>
            </div>
          )}

          {!createPasswordQuery.ok && createPasswordQuery.validToken && (
            <div className='right-form invalid-right-form'>
              <div className='invalid'>
                <br/>
                <h1>Activation link invalid</h1>
                <p className='big-text'>The link you clicked is either expired or invalid.</p>
                <br />
                <p className='big-text'>Please contact your client success representative for assistance.</p>
              </div>
            </div>
          )}

          {createPasswordQuery.ok && createPasswordQuery.validToken && (
            <div className='right-form'>
              <Fragment>
                <br/><br/><br/><br/>
                <h1>Create your Password</h1>
                <p className='big-text'>Help us secure your account by creating a strong password.</p>

                <form onSubmit={handleSubmit} noValidate>
                  <FormError errors={inputErrors} />

                  <Group>
                    <div className='form-input'>
                      <div className='input-label'>New Password</div>
                      <div className='input password login-password new-password'>
                        <input
                          type='password'
                          autoComplete='off'
                          value={input.password}
                          onChange={(v) => handleInputChange('password', v.target.value)}
                          // onChange={(value) => setInput((prevState) => ({ ...prevState, password: value.target.value }))}
                          errors={inputErrors.password}
                          data-attribute-id='create_password_new_password_field'
                        />
                        <i onClick={() => handleShowPassword()} className='fas fa-eye' aria-hidden='true'></i>
                      </div>
                    </div>
                  </Group>
                  <PasswordValidationChecklist input={input}/>

                  <br/>
                    <Button
                      className='login-button full-width primary'
                      text='CREATE'
                      type='submit'
                      loading={loaderState.submit}
                      data-attribute-id="create_password_btn"
                    />
                </form>
              </Fragment>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}

export default CreatePassword
