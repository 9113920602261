import React from 'react'
import { Button } from '@lib'
import PropTypes from 'prop-types'
import bgImage from '@images/networkingBG.png'
import logoImage from '@images/xevant-logo-horizontal.png'
import { useNavigate } from 'react-router-dom'
import sendMfaEmail from '@graphql/mutators/send-mfa-email'
import AuthenticationWrapper from '@shared/AuthenticationWrapper'
import updateMfa from '@graphql/mutators/update-mfa'

const Content = ({ mfaUser }) => {
  const mfaToken = localStorage.getItem('MfaToken')

  const navigate = useNavigate()
  const handleAppSelection = () => {
    updateMfa({
      mfaMethod: null,
      mfaToken
    }).then(function ({ data }) {
      if (data.updateMfa) {
        navigate('/authenticate/scan')
      }
    })
  }
  const handleEmailSelection = () => {
    updateMfa({
      mfaMethod: null,
      mfaToken
    }).then(function ({ data }) {
      if (data.updateMfa) {
        sendMfaEmail({
          id: mfaUser.data.mfa.id,
          email: mfaUser.data.mfa.email
        })
        navigate('/authenticate/mfacode')
      }
    })
  }

  return (
    <div
      className='page-mfa'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle'>
          <div className='select-form'>
            {window.location.hostname.includes('xevant') && (
              <img src={logoImage} />
            )}
            <h2>
              Select your two-factor authentication form
            </h2>
            <p className='select-p'>
            With two-factor authentication (2FA) enabled, you make it harder for unauthorized persons to access your protected information by adding an extra layer of security.
            </p>
            <p className='select-p'>
            Please select below the two-factor authentication tool you want to use.
            </p>

            <Button
data-attribute-id="mfa_select_auth_app_btn" onClick={() => handleAppSelection()} icon='ri:apps-2-fill' iconify={true} text='AUTHENTICATOR APP'
className='login-button primary full-width'
            ></Button>
            <Button data-attribute-id="mfa_select_email_btn" onClick={() => handleEmailSelection()} icon='fas fa-envelope' text='EMAIL AUTHENTICATION' className='login-button primary full-width'></Button>
          </div>
        </div>
      </div>
    </div>
  )
}

Content.propTypes = {
  user: PropTypes.object,
  mfaUser: PropTypes.object
}

const MfaSelect = () => {
  return (
    <AuthenticationWrapper>
      <Content />
    </AuthenticationWrapper>
  )
}

export default MfaSelect
