import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Page, Button, Link } from '@lib'
import { Loader } from '@shared'
import { Group, Input, Submit, FormError } from '@shared/Form'
import { array, object, currentDevice, cookie, getDefaultDashboardPath, axiosGet } from '@utils'

import client from '@graphql/client'
import validateLogin from '@graphql/mutators/validate-login'
import createSession from '@graphql/mutators/create-session'
import userNoticesGql from '@graphql/queries/user-notices'
import authGql from '@graphql/queries/auth'
import clientLogoGql from '@graphql/queries/client-logo'
import clientTitleBarGql from '@graphql/queries/client-title-bar'

import logoImageHorizontal from '@images/xevant-logo-horizontal.png'
import bgImage from '@images/networkingBG.png'
import classNames from 'classnames'

const Login = () => {
  const navigate = useNavigate()
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    username: '',
    password: '',
    ipAddress: '',
    device: currentDevice
  })
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  const [userIP, setUserIP] = useState()
  useEffect(() => {
    if (!userIP) {
      (async () => {
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        setUserIP(data.ip)
        input.ipAddress = data.ip
      })().catch(err => {
        console.log('err fetching ip: ', err)
      })
    }
  }, [userIP])

  const [clientLogo, setClientLogo] = useState('')

  useEffect(() => {
    if (inputErrors.password?.length === 1 && inputErrors.username?.length === 1) {
      document.querySelector('.middle')?.classList.add('login-container-height')
    } else {
      document.querySelector('.middle')?.classList.remove('login-container-height')
    }
  }, [inputErrors])

  useEffect(() => {
    if (!clientLogo) {
      client.query({
        query: clientLogoGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientLogo) {
          axiosGet(`s3/client-logo/${data.clientLogo.logoPath}`).then(({ data }) => {
            setClientLogo(data.url)
          })
        } else if (window.location.hostname.includes('localhost') || window.location.hostname === 'app.xevant.com') {
          setClientLogo(logoImageHorizontal)
        }
      })
    }
  }, [clientLogo])

  const authQuery = useQuery(authGql)
  const user = authQuery?.data?.auth
  const [clientTitleBar, setClientTitleBar] = useState()

  useEffect(() => {
    if (!clientTitleBar) {
      client.query({
        query: clientTitleBarGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientTitleBar?.name) {
          document.getElementById('title').innerHTML = ''
          setClientTitleBar(data.clientTitleBar.name)
          const name = data.clientTitleBar.name
          if (name.includes('_RLS')) {
            const newTitle = name.replace('_RLS', '')
            document.getElementById('title').innerHTML = `${newTitle} - Login`
          } else {
            document.getElementById('title').innerHTML = `${name} - Login`
          }
        } else {
          document.getElementById('title').innerHTML = `${window.location.hostname}`
        }
      })
    }
  }, [clientTitleBar])

  const [navPath, setNavPath] = useState()
  useEffect(() => {
    if (user && user.systemRole) {
      getDefaultDashboardPath(user).then(u => {
        if (window.location.href.includes('siteswitch=true')) {
          const path = u + '?siteswitch=true&client=' + user.client.name
          setNavPath(path)
        } else {
          setNavPath(u)
        }
      })
    }
  }, [user])

  if (user && user.systemRole) {
    if (navPath) {
      return (
        <Navigate replace to={navPath}/>
      )
    } else {
      return <Loader />
    }
  }

  if (authQuery.loading) {
    return (
      <Loader />
    )
  }

  localStorage.clear()

  const handleInputChange = (name, value) => {
    if (inputErrors.credentials) {
      delete inputErrors.credentials
    }

    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
  }

  const handleShowPassword = () => {
    const passInput = document.querySelector('.login-password input')
    if (passInput.type === 'password') {
      document.querySelector('.login-password i').classList.add('fa-eye-slash')
      passInput.type = 'text'
    } else {
      document.querySelector('.login-password i').classList.remove('fa-eye-slash')
      passInput.type = 'password'
    }
  }

  const handleLogin = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}

    if (!input.username) {
      feErrors.username = [{
        message: 'Email required'
      }]
    }

    if (!input.password) {
      feErrors.password = [{
        message: 'Password required'
      }]
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      validateLogin({ ...input }).then(async ({ data, extensions }) => {
        const user = data.validateLogin

        if (user) {
          const mfaExempt = ['securityandcompliance@xevant.com']

          if ((user.isMfaSkipped && user.isMfaSkipExpired === false && user.lastDeviceUsed === currentDevice && user.lastIpUsed === userIP) || ((location.origin === 'https://alcatraz-be.dev.xevant.io' || location.origin === 'https://alcatraz-be.stage.xevant.io') && mfaExempt.includes(user.username))) {
            await createSession({
              storageUrl: localStorage.getItem('devUrl'),
              ip: userIP,
              mfaToken: user.mfaToken,
              device: currentDevice,
              mfaMethod: user.mfaMethod
            }).then(({ data }) => {
              if (data.createSession) {
                // sessionPopup
                cookie.set('csrfCookie', 0, -1)
                cookie.set('sessionExpires', data.createSession.expiresAt, 14)
                cookie.set('sessionStatus', 'true', 14)

                setTimeout(() => {
                  // fetch user notices
                  client.query({
                    query: userNoticesGql,
                    variables: {
                      userId: user.id
                    }
                  }).then(({ data, extensions }) => {
                    if (data.userNotices) {
                      localStorage.setItem('userNotices', JSON.stringify(data.userNotices))
                    }
                  })
                  // end fetch user notices
                }, 1000)
              } else {
                navigate('/authenticate')
              }
            })
          } else {
            navigate('/authenticate')
          }
        } else {
          setLoaderState(prevState => ({ ...prevState, submit: false }))
          setInputErrors(array.groupBy(extensions.errors, 'path'))
        }
      })
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  const hasErrors = Object.keys(inputErrors).length > 0
  // console.log(inputErrors.credentials)
  return (
    <Page
      name='login'
      className='login'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle'>
          <div className='right-form'>
              <div className='login-logo-center'>
                <img src={clientLogo} />
              </div>

              <h1>Log-in</h1>

            <form
              onSubmit={handleLogin}
              noValidate
            >

              <FormError errors={inputErrors} />

              <Group>
                <Input
                  className='login-input'
                  type='text'
                  // icon='fad fa-envelope'
                  label='Username'
                  placeholder='Enter your username'
                  value={input.username}
                  onChange={(value) => handleInputChange('username', value)}
                  errors={inputErrors.username}
                  dataAttrId={'login_username_field'}
                />
              </Group>

              <Group>
                <div className='form-input'>
                  <div className='input-label'>Password</div>
                  <div className='input password login-input login-password'>
                    <input
                      type='password'
                      placeholder='Enter your password'
                      autoComplete='off'
                      value={input.password}
                      onChange={(v) => handleInputChange('password', v.target.value)}
                      errors={inputErrors.password}
                      data-attribute-id='login_password_field'
                    />
                    <i onClick={() => handleShowPassword()} className='fas fa-eye' aria-hidden='true'></i>
                  </div>
                </div>
              </Group>

              <Submit className={classNames('login-submit', 'right', { 'has-error': hasErrors })}>
                <Button
                  type='submit'
                  className='login-button full-width primary'
                  text='Log In'
                  loading={loaderState.submit}
                  data-attribute-id='login_btn'
                />
              </Submit>

              <p className='form-bottom'>
                <Link to='/forgot-password'>Forgot Password?</Link>
              </p>
            </form>
            <div className='form-footer login-footer'>
              {window.location.hostname.includes('xevant') && (
                <div className='copyright'>
                  Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Login
