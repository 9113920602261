import React, { Fragment, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title, Datatable, Tooltip } from '@lib'
import { ListView, Link } from '@shared'
import { axiosGet } from '@utils'
import Swal from 'sweetalert2'
import { Can } from '@caslConfig/can.js'

import fileUploadsGql from '@graphql/queries/file-uploads'
import destroyFileUpload from '@graphql/mutators/destroy-file-upload'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const { directory } = useParams()
  const fileType = directory
  const fileUploadsQuery = useQuery(fileUploadsGql, {
    fetchPolicy: 'network-only',
    variables: { fileType },
    pollInterval: 5000
  })
  const activeClientUser = user.userClients.filter(x => x.clientId === user.clientId)[0]
  if ((!activeClientUser?.isDatalogicUser || (!user?.client?.clientModules?.find(cm => cm.module.name === 'BidLogic') && directory !== 'claims')) && localStorage.getItem('siteSwitching') !== 'true') {
    navigate(-1, { replace: true })
  }

  const handleDownload = async (row) => {
    // Download from AWS S3
    const bucket = user.client.s3BucketName.replace(/\/.*/g, '')
    const key = encodeURIComponent(row.original.filePath.replace(/^\//, ''))

    try {
      axiosGet(`s3/download-url/${bucket}/${key}`).then(async e => {
        if (e?.data?.url) {
          const downloadUrl = e.data.url
          // Initiate the download
          const link = document.createElement('a')
          link.href = downloadUrl
          link.download = row.original.fileName
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
    } catch (error) {
      Swal.fire({
        text: 'Error downloading the file',
        icon: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }

  const handleDelete = (v) => {
    Swal.fire({
      text: 'Are you sure you want to delete this file upload?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        destroyFileUpload({ id: v }).then(() => {
          fileUploadsQuery.refetch()
          Swal.fire({
            text: 'File upload deleted',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
        })
      }
    })
  }

  const columns = useMemo(() =>
    [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'fileName',
        className: 'filename'
      },
      ...(['claims'].includes(fileType) ? [{ Header: 'Claims Type', width: 160, accessor: 'template.name', Cell: ({ value, row }) => { return row.original.template.name.includes('_Medical') ? 'Medical' : (row.original.template.name.includes('BidLogic') ? 'BidLogic' : 'Pharmacy') } }] : []),
      {
        Header: 'Status',
        accessor: 'fileStatus.value'
      },
      {
        Header: 'Actions',
        id: 'actionBtnCol',
        accessor: 'id',
        disableSortBy: true,
        Cell: function createActionButtons ({ value, row }) {
          return (
            <div className='action-buttons'>
            {row.original.fileStatus.value === 'Error' ? <><Link className='action-button' to={`error-summary/${value}`} icon='fas fa-eye' id={`${value}-view-err`} /><Tooltip anchorId={`${value}-view-err`} content='View error summary' /></> : <></>}
            <Can I='update' a='FileUpload'>{row.original.fileStatus.value === 'For Resolution' ? <><Link className='action-button edit-button' to={`file-resolver/${value}`} icon='fas fa-wrench' id={`${value}-resolve`}/><Tooltip anchorId={`${value}-resolve`} content='Resolve errors'/></> : <></>}</Can>
            <Can I='read' a='FileUpload'>{row.original.filePath.includes('/downloads') ? <div onClick={() => handleDownload(row)} id={`${value}-file-download`} className='action-button download-button'><i className='fas fa-file-download'></i><Tooltip anchorId={`${value}-file-download`} content='Download'/></div> : <></>}</Can>
            <Can I='delete' a='FileUpload'><div onClick={() => handleDelete(value)} id={`${value}-delete`} className='action-button delete-button handle-delete'><i className='fas fa-trash status-action'></i></div><Tooltip anchorId={`${value}-delete`} content='Delete'/></Can>
            </div>
          )
        }
      }
    ])

  if (fileUploadsQuery.loading || fileUploadsQuery.error) {
    return null
  }

  const { fileUploads } = fileUploadsQuery.data

  const getTitle = (fileType) => {
    const specialCases = {
      'ndc list': 'NDC List'
    }

    const normalizedDirectory = fileType.toLowerCase()

    if (specialCases[normalizedDirectory]) {
      return specialCases[normalizedDirectory] + ' File Uploads'
    }

    return fileType
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') + ' File Uploads'
  }

  return (
    <Fragment>
        <Title
          text={getTitle(fileType)}
          user={user}
        />

        <div className='dl-file-list'>
          <ListView>
            <Can I='create' a='FileUpload'>
              <Datatable
                columns={columns}
                data={fileUploads}
                newRowBtn={{
                  icon: 'fas fa-arrow-alt-to-top',
                  text: 'New Upload',
                  to: `/datalogic/file-uploads/${directory}/new-upload`
                }}
              />
            </Can>

            <Can not I='create' a='FileUpload'>
              <Datatable
                columns={columns}
                data={fileUploads}
              />
            </Can>
          </ListView>
        </div>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const FileUploads = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default FileUploads
