import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { Can } from '@caslConfig/can.js'
import { Datatable, Tooltip } from '@lib'
import { Link } from '@shared'
import { ListView } from '@shared/ListView'

import questionnairesGql from '@graphql/queries/questionnaires'

const QuestionnaireList = ({
  links
}) => {
  const questionnairesQuery = useQuery(questionnairesGql, {
    fetchPolicy: 'network-only'
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'client.name'
      },
      {
        Header: '# of Events',
        accessor: 'questionnaireCount'
      },
      {
        Header: '# of Users',
        accessor: 'userCount'
      },
      {
        id: 'actionBtnsCol',
        accessor: 'id',
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='update' a='QuestionnaireSetup'>
                <Link
                  className='action-button edit-button'
                  to={`${links.edit}/${value}`}
                  icon='fas fa-pencil-alt'
                  id={`${value}-edit`}
                />
                <Tooltip
                  anchorId={`${value}-edit`}
                  content='Edit'
                />
              </Can>
            </div>
          )
        }
      }
    ],
    []
  )

  if (questionnairesQuery.loading || questionnairesQuery.error) {
    return null
  }

  const { questionnaires } = questionnairesQuery.data

  if (!questionnaires) {
    return null
  }

  return (
    <ListView>
      <Can I='create' a='QuestionnaireSetup'>
        <Datatable
          columns={columns}
          data={questionnaires}
          sortDesc={false}
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New Questionnaire Setup',
            to: '/settings/questionnaire-admin/new'
          }}
        />
      </Can>
      <Can not I='create' a='QuestionnaireSetup'>
        <Datatable
          columns={columns}
          data={questionnaires}
          sortDesc={false}
        />
      </Can>
    </ListView>
  )
}

QuestionnaireList.propTypes = {
  clientId: PropTypes.string,
  templateGroup: PropTypes.string,
  links: PropTypes.object,
  fields: PropTypes.object,
  itemClass: PropTypes.string,
  onDelete: PropTypes.func,
  filters: PropTypes.object,
  value: PropTypes.string
}

QuestionnaireList.defaultProps = {
  clientId: null,
  links: {
    view: '',
    edit: ''
  },
  fields: {
    client: true,
    template: true,
    templateField: true,
    name: true,
    isRequired: false,
    action: true
  },
  onDelete: () => {}, // This is intentional
  filters: {},
  value: null
}

export default QuestionnaireList
