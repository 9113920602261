import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// import { Page, Title, Button, swal } from '@lib'
import { Page, Button, Icon } from '@lib'
import Swal from 'sweetalert2'
import { Group, FormError } from '@shared/Form'
import { PasswordValidationChecklist } from '@shared/index.js'
import { array, object, axiosGet } from '@utils'

import logoImage from '@images/xevant-logo-vertical.png'
import bgImage from '@images/networkingBG.png'

import forgotPasswordTokenGql from '@graphql/queries/forgot-password-token'
import updateForgottenPassword from '@graphql/mutators/update-forgotten-password'
import clientLogoGql from '@graphql/queries/client-logo'
import client from '@graphql/client'
import { isValidPassword } from '@utils/validate-password'

const ChangePassword = () => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    loading: true,
    email: '',
    password: '',
    confirmPassword: '',
    success: null,
    error: null
  })
  const [errors, setErrors] = useState({})
  const params = useParams()
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  useEffect(() => {
    if (state.loading) {
      client.query({
        query: forgotPasswordTokenGql,
        variables: {
          token: params.token
        }
      }).then(({ data }) => {
        if (!data.forgotPasswordToken.ok) {
          setState((prevState) => ({
            ...prevState,
            error: data.forgotPasswordToken.message,
            loading: false
          }))
        } else {
          setState((prevState) => ({
            ...prevState,
            loading: false
          }))
        }
      })
    }
  }, [state.loading])

  const handleInputChange = (name, value) => {
    const newInputError = { ...errors }
    newInputError[name] = []
    setErrors(newInputError)

    const newState = { ...state }
    newState[name] = value
    setState(newState)
  }

  useEffect(() => {
    if (state.password !== '') {
      document.querySelector('.middle')?.classList.add('password-validation-extension')
    } else {
      document.querySelector('.middle')?.classList.remove('password-validation-extension')
    }
  }, [state])

  const [clientLogo, setClientLogo] = useState('')
  useEffect(() => {
    if (!clientLogo) {
      client.query({
        query: clientLogoGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientLogo) {
          axiosGet(`s3/client-logo/${data.clientLogo.logoPath}`).then(({ data }) => {
            setClientLogo(data.url)
          })
        } else if (window.location.hostname.includes('localhost') || window.location.hostname === 'app.xevant.com') {
          setClientLogo(logoImage)
        }
      })
    }
  }, [clientLogo])

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const feErrors = {}

    if (!state.password) {
      feErrors.password = [{
        path: 'password',
        message: 'password required'
      }]
    }

    if (state.password) {
      if (!isValidPassword(state.password)) {
        feErrors.password = [{
          path: 'passwordValidation',
          message: 'The password does not meet the criteria below'
        }]
      }
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      setErrors(feErrors)

      updateForgottenPassword({
        token: params.token,
        password: state.password
      }).then(({ data, extensions }) => {
        if (data.updateForgottenPassword) {
          navigate('/login')

          Swal.fire({
            text: 'Your password has been reset',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
        }

        if (extensions?.errors) {
          setErrors(array.groupBy(extensions.errors, 'path'))
          setLoaderState(prevState => ({ ...prevState, submit: false }))
        }
      })
    } else {
      setErrors(feErrors)
    }
  }

  const handleShowPassword = () => {
    const passInput = document.querySelector('.new-password input')
    if (passInput.type === 'password') {
      document.querySelector('.new-password i').classList.add('fa-eye-slash')
      passInput.type = 'text'
    } else {
      document.querySelector('.new-password i').classList.remove('fa-eye-slash')
      passInput.type = 'password'
    }
  }

  if (state.loading) {
    return null
  }

  return (
    <Page
      name='login'
      className='login'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle'>
          {state.error && (
            <Fragment>
              <div className='left-info'>
                <div className='login-logo-left'>
                  <img src={clientLogo} />
                </div>
                <div className='left-content invalid-left-content'>
                  <div className='left-content-icon'>
                    <Icon className="far fa-times-octagon"/>
                  </div>
                </div>
                {window.location.hostname.includes('xevant') && (
                  <div className='copyright'>
                    Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
                  </div>
                )}
              </div>
              <div className='right-content invalid-right-content'>
                <br/>
                <h1>Invalid link</h1>
                <p className='big-text'>The link you clicked is either expired or invalid.</p>
                <br />
                <p className='big-text'>Please contact your client success representative for assistance.</p>
              </div>
            </Fragment>
          )}

          {!state.error && (
            <div className='right-form'>
              <div className='login-logo-center'>
              <img src={clientLogo} />
              </div>
              <Fragment>
                {!state.success && (
                  <>
                  {/* <Form onSubmit={handleSubmit}> */}
                  <form onSubmit={handleSubmit}>
                    <h1>Enter new password</h1>
                    <p className='big-text margin-bottom'>Enter a new password below to gain access to your account.</p>
                    <FormError errors={errors} />
                    <Group>
                      <div className='form-input'>
                        <div className='input-label'>New Password</div>
                        <div className='input password login-password new-password'>
                          <input
                            type='password'
                            autoComplete='off'
                            value={state.password}
                            onChange={(v) => handleInputChange('password', v.target.value)}
                            // onChange={(value) => setState((prevState) => ({ ...prevState, password: value.target.value }))}
                            errors={errors.password}
                            data-attribute-id="forgot_password_change_password_field"
                          />
                          <i onClick={() => handleShowPassword()} className='fas fa-eye' aria-hidden='true'></i>
                        </div>
                      </div>
                    </Group>

                      <PasswordValidationChecklist input={state}/>

                      <Button
                        type='submit'
                        className='icon-left login-button forgot-password full-width primary'
                        text='Reset Password'
                        loading={loaderState.submit}
                        data-attribute-id="forgot_password_change_password_btn"
                      />

                  </form>
                  <div className='form-footer login-footer'>
                    <div className='copyright'>
                      Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
                    </div>
                  </div>
                  </>
                )}
              </Fragment>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}

export default ChangePassword
