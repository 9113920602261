import client from '../client.js'
import updateQuestionnaireGql from '../mutations/update-questionnaire.gql'

const updateQuestionnaire = (input) => {
  return client.mutate({
    mutation: updateQuestionnaireGql,
    variables: input
  })
}

export default updateQuestionnaire
