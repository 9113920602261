import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SiteSwitcher } from '@shared'
import { Button, Tooltip, Bookmark } from '@lib'
import { useParams, useLocation } from 'react-router-dom'

const Title = ({
  text,
  isDashboard,
  isDashboardExecution,
  user,
  className,
  setShowAlertsModal,
  setShowSubscriptionModal,
  getShareLink,
  makeJumpToClaimsMenu,
  revertView,
  children
}) => {
  
  const workbooks = ['TrendLogic', 'BL3_0', 'RebateLogicProposal', 'MedicalProposal', 'MedicalRebates', 'PharmacyRebates']
  const location = useLocation();

  /** Getting the route or the workbook */
  const { workbook } = useParams()

  const disableFilterAndSubscribe = isDashboardExecution || workbooks.includes(workbook) || location.pathname === '/datalogic'
  const showDashboardActions = !(workbooks.includes(workbook) || location.pathname.includes('/datalogic') || location.pathname.startsWith('/settings') || location.pathname.startsWith('/help-center'))

  return (
    <>
      {text && (
        <div className='page-header'>
          <div className='title-container'>
            <div className={classNames('title', className)}>
              {text}
              {children}
            </div>
          </div>

            <div className='actions-container'>
            { (showDashboardActions) && (
              <>
                <Bookmark />
                <div id='tableau-viz-actions--corners'>
                {
                  // Next line checks if client has AlertLogic module
                  (user?.client?.clientModules?.find(cm => cm.module.name === 'AlertLogic') &&
                    <Button
                      className='standard primary alert'
                      icon='fas fa-bell'
                      text='Create an alert'
                      onClick={() => setShowAlertsModal(true)}
                      upperCase={false}
                    />
                  )
                }

                <Button
                  className='standard primary '
                  id='undo-button'
                  icon='fas fa-undo'
                  onClick={() => revertView()}
                  upperCase={false}
                  disabled={disableFilterAndSubscribe}
                />
                <Tooltip
                  anchorId='undo-button'
                  content='Clear all filters'
                  className='white'
                />

                <Button
                  className='standard primary subscribe'
                  iconify={true}
                  icon='fluent:mail-add-16-filled'
                  id='subscribe-button'
                  onClick={() => setShowSubscriptionModal(true)}
                  upperCase={false}
                  disabled={disableFilterAndSubscribe}
                />
                <Tooltip
                  anchorId='subscribe-button'
                  content='Subscribe to this view'
                  className='white'
                />
                <Button
                  className='standard jtcBtn primary'
                  icon='fas fa-file-export'
                  data-tip='Claims Detail'
                  id='claims-button'
                  onClick={() => makeJumpToClaimsMenu()}
                  upperCase={false}
                  disabled={isDashboardExecution || user.userClients.find(uc => uc.clientId === user.clientId).isJtcDisabled && workbook === 'RebateLogicProposal'}
                />
                <Tooltip
                  anchorId='claims-button'
                  content='Claims Detail'
                  className='white'
                />

                <Button
                  className='standard xevShareBtn primary'
                  icon='fas fa-copy'
                  onClick={() => getShareLink()}
                  id='share-button'
                  upperCase={false}
                />
                <Tooltip
                  anchorId='share-button'
                  content='Copy link'
                  className='white'
                />
                </div>
              </>
            )}
            { (user) && (
              <SiteSwitcher user={user}/>
            )}
            </div>

        </div>
      )}
    </>
  )
}

Title.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  isDashboard: PropTypes.bool,
  isDashboardExecution: PropTypes.bool,
  user: PropTypes.object,
  setShowAlertsModal: PropTypes.func,
  setShowSubscriptionModal: PropTypes.func,
  getShareLink: PropTypes.func,
  makeJumpToClaimsMenu: PropTypes.func,
  revertView: PropTypes.func,
  children: PropTypes.node
}

Title.defaultProps = {
  text: null,
  className: null,
  user: null,
  isDashboard: false
}

export default Title
