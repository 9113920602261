import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@lib'
import { Group, FormError } from '@shared/Form'
import { array, currentDevice, cookie, axiosGet } from '@utils'
import client from '@graphql/client'

import createSession from '@graphql/mutators/create-session'
import clientLogoGql from '@graphql/queries/client-logo'

import AuthenticationWrapper from '@shared/AuthenticationWrapper'
import logoImage from '@images/xevant-logo-vertical.png'

const Content = () => {
  const mfaToken = localStorage.getItem('MfaToken')
  const [mfaCode, setInput] = useState('')
  const [isMfaSkipped, setChecked] = useState(false)
  const [inputErrors, setInputErrors] = useState([])

  const [userIP, setUserIP] = useState()
  useEffect(() => {
    if (!userIP) {
      (async () => {
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        setUserIP(data.ip)
      })().catch(err => {
        console.log('err fetching ip: ', err)
      })
    }
  }, [userIP])

  const handleSubmit = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    if (mfaCode === '') {
      setInputErrors([{
        type: 'input',
        message: 'Token incorrect or expired'
      }])
    } else {
      createSession({
        ip: userIP,
        mfaToken,
        mfaCode,
        isMfaSkipped,
        device: currentDevice,
        mfaMethod: 'AUTHENTICATOR'
      }).then(({ data, extensions }) => {
        if (data.createSession !== null) {
          // sessionPopup
          cookie.set('csrfCookie', 0, -1)
          cookie.set('sessionExpires', data.createSession.expiresAt, 14)
          cookie.set('sessionStatus', 'true', 14)
          // end sessionPopup
          // navigate('/settings')
          // navigate(getDefaultDashboardPath(data.createSession.user))
        } else {
          setInputErrors(extensions.errors)
        }
      })
    }
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleSubmit()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [mfaCode])

  return (
    <>
    <FormError errors={inputErrors} />

    <Group>
      <div className='mfa-code-digits'>
        <input
          type='text'
          name='verificationCode'
          maxLength='6'
          pattern="\d*"
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
          }}
          placeholder='xxxxxx'
          className='custom-mfa-input'
          value={mfaCode}
          onChange={(e) => setInput(e.target.value)}
          data-attribute-id="mfa_code_field"
        />
      </div>
    </Group>

    <Group className={`mfa-skip-check ${inputErrors.length ? 'has-error' : ''}`}>
      <div className='checkbox'>
        <input
          value='yes'
          type='checkbox'
          checked={isMfaSkipped}
          onChange={() => setChecked(!isMfaSkipped)}
          data-attribute-id="mfa_remember_checkbox_field"
        />
      </div>
      <label>
        Remember this code for 14 days
      </label>
    </Group>

    <div className='mfa-bottom-buttons'>
      <Button
        type='button'
        className={`login-button primary full-width ${inputErrors.length ? 'has-error' : ''}`}
        text='VERIFY'
        onClick={handleSubmit}
        data-attribute-id="mfa_verify_btn"
      />
    </div>
    </>
  )
}
Content.propTypes = {
  user: PropTypes.object,
  mfaUser: PropTypes.object
}

const Verify = () => {
  const [clientLogo, setClientLogo] = useState('')
  useEffect(() => {
    if (!clientLogo) {
      client.query({
        query: clientLogoGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientLogo) {
          axiosGet(`s3/client-logo/${data.clientLogo.logoPath}`).then(({ data }) => {
            setClientLogo(data.url)
          })
        } else if (window.location.hostname.includes('localhost') || window.location.hostname === 'app.xevant.com') {
          setClientLogo(logoImage)
        }
      })
    }
  }, [clientLogo])

  return (
    <AuthenticationWrapper>
      <>
      <div className='right-form'>
        <div className='login-logo-center'>
          <img src={clientLogo} />

        </div>

        <h1 id='mfa-header'>Enter your 6-digit code</h1>
        <p className='big-text'>We noticed a login from a device and/or location you don&apos;t usually use, please open your authenticator app and enter below the 6-digit code to sign-in securely. </p>

        <form
          onSubmit={(evt) => evt.preventDefault()}
          noValidate
        >
          <Content />
          <div className='form-footer login-footer'>
            {window.location.hostname.includes('xevant') && (
              <div className='copyright'>
                Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
              </div>
            )}
          </div>
        </form>
      </div>
      </>
    </AuthenticationWrapper>
  )
}

export { Content as VerifyInner }

export default Verify
