import React, { useMemo, useState } from 'react'
import { Datatable, Tooltip, Loader } from '@lib'
import Swal from 'sweetalert2'
import { ListView } from '@shared/ListView'
import { time } from '@utils'
import { useQuery } from '@apollo/client'
import { axiosGet } from '@utils'
import axios from 'axios'

import planReviewDownloadsGql from '@graphql/queries/plan-review-downloads'
import destroyPlanReviewDownload from '@graphql/mutators/destroy-plan-review-download'

const PlanReviewDownloadList = ({ user }) => {
    const [planReviewDownloadCheckboxes, setplanReviewDownloadCheckboxes] = useState([])
    const planReviewDownloads = useQuery(planReviewDownloadsGql, {
        fetchPolicy: 'network-only'
    })
    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Created At',
            accessor: n => {
                if (user.timezone) {
                    const formattedTime = time.utc(n.createdAt).tz(user.timezone).format('MM/DD/YYYY hh:mm A')
                    return formattedTime === 'Invalid Date' ? '-' : formattedTime
                } else {
                    return n.createdAt === 'Invalid Date' ? '-' : n.createdAt
                }
            }
        },
        {
            id: 'statusCol',
            Header: 'Status',
            accessor: 'status'
        },
        {
            Header: 'Actions',
            disableSortBy: true,
            accessor: n => n,
            Cell: function createActionButtons({ value }) {
                return (
                    <div className='action-buttons'>
                        <div id={`${value.id}-delete`} onClick={() => handleDelete(value.id)} className='action-button delete-button'>
                            <i className='fas fa-trash'></i>
                        </div>
                        <Tooltip content='Delete' anchorId={'delete' + value.id} />
                        <div id={`${value.id}-download`} onClick={() => handleDownload(value.s3Key)} className={value.s3Key ? 'action-button download-button' : 'action-button download-button disabled'}>
                            <i className='fas fa-download'></i>
                        </div>
                        <Tooltip content='Download' anchorId={'download' + value.id} />
                    </div>
                )
            }
        }
    ], [planReviewDownloadCheckboxes])


    const handleDelete = (id) => {
        Swal.fire({
            text: 'Are you sure you want to delete this plan review download?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete'
          }).then((result) => {
            if (result.isConfirmed) {
                destroyPlanReviewDownload({ id }).then(({ data }) => {
                    if (data.destroyPlanReviewDownload) {
                        planReviewDownloads.refetch()
                        Swal.fire({
                            text: 'Plan review download deleted',
                            icon: 'success',
                            timer: 3000,
                            showConfirmButton: false
                        })
                    }
                })
            }
        })
    }

    const downloadFile = async (s3Key) => {
        try {
          const s3Url = await axiosGet(`s3/plan-review-downloads${s3Key}`)
        
          if (s3Url.status === 200) {
            window.open(s3Url.data.url, '_blank')
            Swal.fire({
                text: 'File downloaded',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            })
          } else {
            Swal.fire({
                text: 'Error downloading file',
                icon: 'error',
                timer: 3000,
                showConfirmButton: false
            })
          }

        } catch (error) {
          Swal.fire({
            text: 'Error downloading file',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false
        })
        }
      };

    const handleDownload = async (s3Key) => {
        await downloadFile(s3Key)
    }

    if (planReviewDownloads.loading) return null
    const planReviewDownloadItems = planReviewDownloads.data.planReviewDownloads

    return (
        <div className='plan-review-request-list-wrap'>
            <ListView>
                <Datatable
                    columns={columns}
                    data={planReviewDownloadItems}
                />
            </ListView>
        </div>
    )
}

export default PlanReviewDownloadList
