import React, { Fragment, useState, useRef } from 'react'
import Dashboard from '@wrappers/Dashboard'
import { Title, Link, Icon } from '@lib'
import PropTypes from 'prop-types'
import { Icon as Iconify } from '@iconify/react'
import { SyncPlanReview } from '@shared'

import { Can } from '@caslConfig/can.js'

const Content = ({ user }) => {
  const [showSyncModal, setShowSyncModal] = useState(false)
  const vizRef = useRef(null)
  return (
    <Fragment>
      <Title text='Settings' user={user}/>

      <Can I='read' an='Account'>
        <section className='maintenance'>
          <h1>Accounts</h1>
          <ul>
            <Can I='read' a='User'>
              <li>
                <Link to='/settings/users'>
                  <Icon className='fas fa-users' />
                  <span>Users</span>
                </Link>
              </li>
            </Can>
            <Can I='read' a='Client'>
              <li>
                <Link to='/settings/clients'>
                  <Icon className='fas fa-user-tie' />
                  <span>Clients</span>
                </Link>
              </li>
            </Can>
          </ul>
        </section>
      </Can>

      <Can I='read' a='Configuration'>
        <section className='maintenance'>
          <h1>Configurations</h1>
          <ul>
            <Can I='create' a='MasterReference'>
              <li>
                <Link to='/settings/master-references'>
                  {/* <Icon className='fas fa-books' /> */}
                  <Iconify width='45px' icon='bxs:book-bookmark' />
                  <span>Master References</span>
                </Link>
              </li>
            </Can>
            <Can I='create' a='Resource'>
              <li>
                <Link to='/settings/trainings-and-use-cases'>
                  <Icon className='fas fa-book-reader' />
                  <span>Trainings & Use Cases</span>
                </Link>
              </li>
            </Can>
            <Can I='create' a='Notice'>
              <Can I='create' a='ReleaseNote'>
                <li>
                  <Link to='/settings/notices-and-release-notes'>
                    <Icon className='fas fa-sticky-note' />
                    <span>Notices & Release Notes</span>
                  </Link>
                </li>
              </Can>
            </Can>
            <Can I='read' a='QuestionnaireSetup'>
              <li>
                <Link to='/settings/questionnaire-admin'>
                  <Icon className='fas fa-clipboard-list-check' />
                  <span>Questionnaire Admin</span>
                </Link>
              </li>
            </Can>
            <Can I='read' a='SystemConfig'>
              <li>
                <Link to='/settings/system-config'>
                  <Iconify width='45px' icon='ic:baseline-settings-applications' />
                  <span>System Config</span>
                </Link>
              </li>
            </Can>
            <Can I='read' a='SyncPlanReview'>
              <li>
                <div onClick={() => { setShowSyncModal(true) }} className='sync-plan-review'>
                  <Iconify width='45px' icon='ic:baseline-sync' />
                  <span>Sync Plan Reviews</span>
                </div>
              </li>
            </Can>
          </ul>
          {showSyncModal && <SyncPlanReview show={showSyncModal} setShow={setShowSyncModal} user={user} vizRef={vizRef}/>}
        </section>
      </Can>

      <Can I='read' a='SystemLog'>
        <section className='maintenance'>
          <h1>System Logs</h1>
          <ul>
            <Can I='read' an='AuditLog'>
              <li>
                <Link to='/settings/audit-logs'>
                  <Iconify width='50px' icon='bxs:user-detail' />
                  <span>Audit Logs</span>
                </Link>
              </li>
            </Can>
          </ul>
        </section>
      </Can>

      <div id='vizWrapperSettings' ref={vizRef}/>

    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const Settings = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default Settings
